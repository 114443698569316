

.new_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin: 40px 0px 30px 0px;
}
.main_image_image {
  width: 250px;
  height: 250px;
  object-fit: cover;
}
.post-post{
  font-size: 18px !important;
  color: black;

}

.team-box-2 {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 6rem 3rem;
  overflow: hidden;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  border: 10px solid transparent;
  /* width: 500px; */
  width: 27%;
}

.team-box-2::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  background: #f5f5f5;
  opacity: 1;
  top: 0;
  left: 0;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.team-box-2:hover::before {
  background: transparent linear-gradient(90deg, #01448F 0%, #0252ad 100%);
}

.team-shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  transition: all 0.5s ease;
}

.team-top {
  top: 0;
  right: 0;
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.img,
img {
  max-width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  height: auto;
}

.team-half-circle {
  bottom: -100px;
  left: -100px;
  position: absolute;
  z-index: -1;
  transition: all 0.5s ease;
}

.team-box-2:hover .team-half-circle {
  bottom: 0px;
  left: 0px;
}

.team-box-2:hover {
  border: 5px solid #01448f;
}

.team-box-2:hover .team-shape-2 {
  bottom: -100%;
}

.team-box-2:hover .team-top {
  opacity: 1;
}

.team-box-2:hover .team-half-circle {
  bottom: 0px;
  left: 0px;
}

.team-box-2:hover .team-bio-2 {
  margin-bottom: 3rem;
}

.team-box-2 .team-bio-2 {
  margin-bottom: 3rem;
}

a,
a:active,
a:focus,
a:hover {
  outline: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
.team-box-2:hover .team-bio-2 .heading-4 {
  color: white;
}

.team-box-2 .team-bio-2 .heading-4 {
  text-transform: capitalize;
  line-height: 1.4;
}

.heading-4 {
  font-size: 22px;
  line-height: 1.2;
  font-weight: 800;
}
.team-box-2:hover .team-bio-2 span {
  color: white;
}

.team-box-2 .team-bio-2 span {
  display: block;
  font-size: 1.5rem;
  line-height: 1;
}

.team-box-2 .team-pic-2 {
  margin-bottom: 4rem;
}

.team-box-2:hover .team-pic-2 img {
  background: var(--clr-sec);
}

.team-box-2 .team-pic-2 img {
  border-radius: 50%;
}
.team-box-2 .team-social-2 .team-social {
  justify-content: center;
}
.team-social {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.team-social li a i:hover {
  background-color: white;
  color: black;
}
.team-social li a i {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  background-color: white;
  color: black;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  transition: all 0.5s ease;
}

@media only screen and (max-width: 1600px) {
  .team-box-2 {
    width: 30%;
  }
}

@media only screen and (max-width: 1600px) {
  .team-box-2 {
    width: 30%;
  }
  .team-box-2 .team-bio-2 span {
    font-size: 1.3rem;
}
.heading-4 {
  font-size: 18px;
}
.main_image_image {
  width: 230px;
  height: 230px;
  object-fit: cover;
  border: 3px solid white;
}
}
@media only screen and (max-width: 1400px) {
  .team-box-2 {
    width: 40%;
 
}
}
@media only screen and (max-width: 1200px) {
  .team-box-2 {
    width: 45%;
 
}
}
@media only screen and (min-width: 600px) and (max-width:1000px) {
  .team-box-2 {
    width: 48%;
    height: 600px;
}
.main_image_image {
  width: 200px;
  height: 200px;
}
}
 
@media only screen and (max-width: 800px) {
  .team-box-2 {
    width: 45%;
 
}
}
@media only screen and (max-width: 650px) {
  .team-box-2 {
    width: 95%;
 
}
}


@media only screen and (min-width: 400px) and (max-width:599px) {
  .team-box-2 {
    height: 580px;
}
.main_image_image {
  width: 180px;
  height: 180px;
}
}