/*---------------------------------
# Responsive
-----------------------------------*/
@media screen and (min-width: 1200px) and (max-width:1400px) {
  .feature-title {
    font-size: 18px !important;
}
}
@media screen and (min-width: 1000px) and (max-width:1199px) {
  .feature-title {
    font-size: 14px !important;
}
}
@media screen and (min-width: 1200px) and (max-width:1515px) {
  .about-two__img-2 img {
    width: auto;
    height: 176px;
}
}
@media screen and (min-width: 1000px) and (max-width:1199px) {
  .about-two__img-2 img {
    height: 354px !important;
}
}

@media screen and (min-width: 1200px) and (max-width: 1399.98px) {
  .col-lg-2 {
    width: 19.666667%;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .col-lg-2 {
    width: 19.666667%;
  }

}

@media screen and (max-width:1000px) {
  .about-two{
    margin-top: 30px;

  }
  
}



@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .col-lg-2 {
    width: 24.666667%;
  }
}
 
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0px !important;
    padding-left: 0px !important ;
  }
  .nav-item {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: var(--govity-gray);
  }
  .about-two__shape-3 {
    display: none;
  }
  .services-two__left {
    text-align: center;
  }
  .department-two__title {
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
  }
  .department-two__content {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 13px;
    padding: 15px 16px 15px;
  }
  .portfolio-two__bg-box {
    display: none;
  }
  .portfolio-two__right {
    background-color: #0ca554;
  }
  .portfolio-two__right {
    margin-left: 0px;
  }
  .portfolio-two__img::before {
    position: absolute;
    top: 29px;
  }
  .section-title__title {
    font-size: 30px;
  }
  .event-two__text-1 {
    font-size: 16px;
    line-height: 30px;
  }
  .event-two__points {
    position: relative;
    display: block;
    align-items: center;
  }
  .event-two__points li + li {
    margin-left: 0px;
  }
  .thm-btn {
    padding: 15px 22px 15px;
  }
  .portfolio-two__img {
    margin-top: 30px;
  }
  .portfolio-two__shape-1 {
    display: none;
  }
  .bc-r h5 {
    color: #01448f;
    font-weight: 600;
    font-size: 15px !important;
  }
}
 
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .main-menu-two__btn-box {
    display: none !important;
  }
  .srch-ic {
    display: none !important;
  }
  .nav-sm {
    padding: 22px 20;
  }
  .top-header {
    display: none !important;
  }
  .up-t {
    display: none;
  }
  .header-img {
    height: 530px;
  }
  .rgba_img {
    height: 530px;
  }
  .caro-t p {
    border-top: solid 5px #0ca554;
    width: 74%%;
    font-size: 15px !important;
  }
  .portfolio-two__img {
    padding-top: 10px;
  }
  .s-icon {
    display: none !important;
  }
  .navbar {
    height: 12%;
  }
  .be-f {
    margin-top: 100px !important;
  }
  .caro-t h2 {
    font-weight: 600;
    font-size: 35px;
  }
  .btn-box a {
    padding: 15px !important;
  }
  .ms-5 {
    display: none;
  }
  .gv-d {
    top: 100px;
  }
  .f-img {
    margin-left: 0px !important;
  }
  .feature-icon {
    top: 400px;
    left: 50%;
  }
  .feature-iconn {
    top: 926px;
    left: 50%;
  }
  .feature-box2 {
    margin-top: 28px;
  }
  .services-two__shape-1 {
    display: none;
  }
  .section-title__title {
    font-size: 25px;
    line-height: 40px;
  }
  .section-title__title {
    font-size: 45px;
    line-height: 56px;
  }
  .services-two__right {
    margin-top: 15px;
  }
  .portfolio-two__single--all {
    min-height: 170px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .counter-two__list {
    display: inline-block;
  }
  .counter-two__list {
    display: block;
    text-align: center;
  }
  .counter-two__list li {
    position: relative;
    display: block;
    align-items: center !important;
    margin: 0 auto;
    justify-content: center;
  }
  .counter-two__count {
    margin-right: 0px !important;
  }
  .portfolio-two__bg-box {
    display: none;
  }
  .event-two__points li + li {
    margin-left: 0px;
  }
  .thm-btn {
    padding: 16px 25px 16px;
  }
  .overlay-t {
    height: 250px;
    position: absolute;
    top: 56px;
    left: 236px;
    width: 463px;
  }
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: -56px;
  }
  .event-two__left::before {
    position: absolute;
    top: 0;
    left: -9916px;
    right: -125px;
    bottom: 0;
    background-color: var(--govity-black);
  }
  .event-two__points {
    display: block;
  }
  .overlay-r {
    height: 256px;
    position: absolute;
    top: 332px;
    left: 238px;
    width: 465px;
  }
  .site-footer__top {
    display: inline-block;
    padding: 14px 18px 16px;
  }
  .site-footer__middle {
    display: block;
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .footer-widget__email-input-box {
    width: 100%;
  }
  .event-two__left {
    padding-top: 65px;
    padding-bottom: 35px;
    padding-right: 15px;
  }
  .news-two__single {
    margin-bottom: 30px;
  }
  .about-two__left {
    margin-right: 176px;
  }
  .about-two__shape-2 {
    display: none;
  }
  .f-img {
    margin-left: 0px !important;
    height: 315px;
  }
  .about-two__img {
    margin-top: 88px;
  }
  .carousel-indicators ::before {
    z-index: -1 !important;
    width: 4px;
    height: 197px;
    top: -47px;
    right: 541px;
  }
  .news-two__content {
    padding: 15px 22px 12px;
  }
  .news-two__img-box {
    position: relative;
    display: block;
  }
  .about-two__right {
    margin-top: 45px;
  }
  .about-two__points {
    display: block;
  }
  .about-two__points-title-box .icon {
    width: 25px;
    height: 25px;
  }
  .about-two {
    margin-top: 840px;
  }
  .portfolio-two__right {
    padding: 22px !important;
    padding-left: 25px !important;
    margin-left: 0px;
  }
  .portfolio-two__right {
    margin-left: 0px;
    padding: 0;
    margin-top: 0;
    background-color: #0ca554;
    margin-bottom: 25px;
  }
  .portfolio-two__points-list li .content h3 {
    font-size: 22px;
  }
  .brand-logo {
    padding: 0px 0;
    position: relative;
  }
  .myfrm {
    display: none !important;
  }
  .brand-one__title {
    display: none;
  }
  .about-two__shape-3 {
    display: none;
  }
}
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .main-menu-two__btn-box {
    display: none !important;
  }
  .srch-ic {
    display: none !important;
  }
  .nav-sm {
    padding: 22px 20;
  }
  .top-header {
    display: none !important;
  }
  .up-t {
    display: none;
  }
  .header-img {
    height: 530px;
  }
  .rgba_img {
    height: 530px;
  }
  .caro-t p {
    border-top: solid 5px #0ca554;
    width: 74%%;
    font-size: 15px !important;
  }
  .s-icon {
    display: none !important;
  }
  .navbar {
    height: 12%;
  }
  .be-f {
    margin-top: 100px !important;
  }
  .caro-t h2 {
    font-weight: 600;
    font-size: 35px;
  }
  .btn-box a {
    padding: 15px !important;
  }
  .ms-5 {
    display: none;
  }
  .gv-d {
    top: 100px;
  }
  .f-img {
    margin-left: 0px !important;
  }
  .feature-iconn {
    top: 784px;
    left: 50%;
  }
  .feature-icon {
    top: 370px;
    left: 50%;
  }
  .feature-box2 {
    margin-top: 28px;
  }
  .services-two__shape-1 {
    display: none;
  }
  .section-title__title {
    font-size: 25px;
    line-height: 40px;
  }
  .section-title__title {
    font-size: 26px;
    line-height: 40px;
  }
  .counter-two__list {
    display: inline-block;
  }
  .counter-two__list {
    display: block;
    text-align: center;
  }
  .counter-two__list li {
    position: relative;
    display: block;
    align-items: center !important;
    margin: 0 auto;
    justify-content: center;
  }
  .counter-two__count {
    margin-right: 0px !important;
  }
  .portfolio-two__bg-box {
    display: none;
  }
  .event-two__points li + li {
    margin-left: 0px;
  }
  .thm-btn {
    padding: 16px 25px 16px;
  }
  .news-two__img-box {
    padding: 12px;
  }
  .event-two__points {
    display: block;
  }
  .site-footer__top {
    display: inline-block;
    padding: 21px 25px 21px;
  }
  .site-footer__middle {
    display: block;
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .footer-widget__email-input-box {
    display: block;
    width: 72%;
  }
  .footer-widget__Contact-text {
    margin-bottom: 0px;
  }
  /* .news-two__single {
    display: block;
    margin-bottom: 30px;
  } */
  .event-two__left {
    padding-top: 65px;
    padding-bottom: 35px;
    padding-right: 15px;
  }
  .footer-widget__Contact-list li {
    font-size: 13px;
  }
  .footer-widget__Contact-list li .icon {
    margin-right: 6px;
    font-size: 13px;
  }
  .social-one__text {
    font-size: 21px;
    line-height: 30px;
  }
  .social-one__text-two {
    font-size: 16px;
  }
  .news-two__content {
    padding: 12px 16px 12px;
  }
  .about-two__shape-1 {
    display: none;
  }
  .about-two__img-2 {
    display: none;
  }
  .about-two__shape-2 {
    display: none;
  }
  .about-two__img {
    width: 168%;
    margin-top: 40px;
  }
  .portfolio-two__single--all {
    min-height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .portfolio-two__all-portfolio-text {
    margin-bottom: 0px;
  }
  .brand-logo {
    padding: 0px 0;
  }
  .overlay-t {
    left: 54px;
    width: 466px;
  }
  .overlay-r {
    left: 56px;
    width: 466px;
  }
  .about-two__right {
    margin-top: 45px;
  }
  .about-two__points {
    display: block;
  }
  .about-two__points-title-box .icon {
    width: 25px;
    height: 25px;
  }
  .about-two {
    margin-top: 840px;
  }
  .portfolio-two__right {
    padding: 22px !important;
    padding-left: 25px !important;
    margin-left: 0px;
  }
  .portfolio-two__right {
    margin-left: 0px;
    padding: 0;
    margin-top: 0;
    background-color: #0ca554;
    margin-bottom: 25px;
  }
  .portfolio-two__points-list li .content h3 {
    font-size: 22px;
  }
  .myfrm {
    display: none !important;
  }
  .brand-one__title {
    display: none;
  }
  .about-two__shape-3 {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .footer-widget__email-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .thm-btn {
    width: 100% !important;
  }
}

@media only screen and (max-width: 560px) {
  .news-two__single {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .news-two__img-box {
    width: 100%;
  }

  .news-two__img {
    width: 100%;
  }

  .news-two__img {
    width: 100% !important;
  }

  /* +++++++++++++++++++++++++ news letter ******************* */

  .site-footer__top {
    display: flex !important;
    flex-direction: column !important;
  }
  .footer-widget__subscribe-text p {
    text-align: center;
    width: 100%;
    padding: 10px 0px;
  }
  .footer-widget__subscribe-text {
    width: 100%;
  }
  .footer-widget__subscribe-btn {
    margin: auto;
  }
}

@media screen and (min-width: 381px) and (max-width: 575.98px) {
  .main-menu-two__btn-box {
    display: none !important;
  }
  .srch-ic {
    display: none !important;
  }
  .nav-sm {
    padding: 22px 20;
  }
  .top-header {
    display: none !important;
  }
  .up-t {
    display: none;
  }
  .header-img {
    height: 530px;
  }
  .rgba_img {
    height: 530px;
  }
  .caro-t p {
    border-top: solid 5px #0ca554;
    width: 74%%;
    font-size: 15px !important;
  }
  .s-icon {
    display: none !important;
  }
  .navbar {
    height: 12%;
  }
  .be-f {
    margin-top: 100px !important;
  }
  .caro-t h2 {
    font-weight: 600 !important;
    font-size: 35px !important;
  }
  .btn-box a {
    padding: 15px !important;
  }
  .ms-5 {
    display: none;
  }
  .gv-d {
    top: 100px;
  }
  .f-img {
    margin-left: 0px !important;
  }
  .feature-icon {
    top: 370px;
    left: 50%;
  }
  .feature-iconn {
    top: 786px;
    left: 50%;
  }
  .feature-box2 {
    margin-top: 28px;
  }
  .services-two__shape-1 {
    display: none;
  }
  .section-title__title {
    font-size: 25px;
    line-height: 40px;
  }
  .section-title__title {
    font-size: 26px !important;
    line-height: 40px !important;
  }
  .counter-two__list {
    display: inline-block;
  }
  .counter-two__list {
    display: block;
    text-align: center;
  }
  .counter-two__list li {
    position: relative;
    display: block;
    align-items: center !important;
    margin: 0 auto;
    justify-content: center;
  }
  .counter-two__count {
    margin-right: 0px !important;
  }
  .portfolio-two__bg-box {
    display: none;
  }
  .event-two__points li + li {
    margin-left: 0px;
  }
  .thm-btn {
    padding: 16px 25px 16px;
  }
  .overlay-t {
    height: 256px;
    position: absolute;
    top: 48px;
    left: 90px;
    width: 466px;
  }
  .carousel-indicators {
    display: none !important;
  }
  .event-two__points {
    display: block;
  }
  .overlay-r {
    height: 256px;
    position: absolute;
    top: 329px;
    left: 93px;
    width: 466px;
  }
  .event-two__img {
    display: none;
  }
  .site-footer__top {
    display: inline-block;
    padding: 21px 25px 21px;
  }
  .site-footer__middle {
    display: block;
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .footer-widget__email-input-box {
    display: block;
    width: 55%;
  }
  .news-two__single {
    display: block;
    margin-bottom: 30px;
  }
  .event-two__left {
    padding-top: 65px;
    padding-bottom: 35px;
    padding-right: 15px;
  }
  .news-two__content {
    padding: 15px 10px 12px;
  }
  .about-two__shape-1 {
    display: none;
  }
  .about-two__img-2 {
    display: none;
  }
  .about-two__shape-2 {
    display: none;
  }
  .about-two__img img {
    width: 67%;
    margin-top: 40px;
  }
  .about-two__right {
    margin-top: 45px;
  }
  .about-two__points {
    display: block;
  }
  .about-two__points-title-box .icon {
    width: 25px;
    height: 25px;
  }
  .about-two {
    margin-top: 840px;
  }
  .portfolio-two__right {
    padding: 22px !important;
    padding-left: 25px !important;
    margin-left: 0px;
  }
  .portfolio-two__right {
    margin-left: 0px;
    padding: 0;
    margin-top: 0;
    background-color: #0ca554;
    margin-bottom: 25px;
  }
  .portfolio-two__points-list li .content h3 {
    font-size: 22px;
  }
  .about-two__shape-3 {
    display: none;
  }
  .myfrm {
    display: none !important;
  }
  .brand-one__title {
    display: none;
  }
}
@media screen and (min-width: 320px) and (max-width: 380.98px) {
  .main-menu-two__btn-box {
    display: none !important;
  }
  .srch-ic {
    display: none !important;
  }
  .nav-sm {
    padding: 22px 20;
  }
  .top-header {
    display: none !important;
  }
  .up-t {
    display: none;
  }
  .header-img {
    height: 530px;
  }
  .rgba_img {
    height: 530px;
  }
  .caro-t p {
    border-top: solid 5px #0ca554;
    width: 74%%;
    font-size: 15px !important;
  }
  .portfolio-two__all-portfolio-icon a {
    font-size: 35px;
  }
  .portfolio-two__all-portfolio-icon a {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
  .brand-logo {
    padding: 0px 0;
  }
  .portfolio-two__single--all {
    min-height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .s-icon {
    display: none !important;
  }
  .navbar {
    height: 12%;
  }
  .be-f {
    margin-top: 100px !important;
  }
  .caro-t h2 {
    font-weight: 600;
    font-size: 35px;
  }
  .btn-box a {
    padding: 15px !important;
  }
  .ms-5 {
    display: none;
  }
  .gv-d {
    top: 100px;
  }
  .f-img {
    margin-left: 0px !important;
  }
  .feature-icon {
    top: 400px;
    left: 50%;
  }
  .feature-iconn {
    top: 850px;
    left: 50%;
  }
  .about-two__shape-3 {
    display: none;
  }
  .feature-box2 {
    margin-top: 28px;
  }
  .services-two__shape-1 {
    display: none;
  }
  .section-title__title {
    font-size: 25px;
    line-height: 40px;
  }
  .section-title__title {
    font-size: 26px;
    line-height: 40px;
  }
  .counter-two__list {
    display: inline-block;
  }
  .counter-two__list {
    display: block;
    text-align: center;
  }
  .counter-two__list li {
    position: relative;
    display: block;
    align-items: center !important;
    margin: 0 auto;
    justify-content: center;
  }
  .counter-two__count {
    margin-right: 0px !important;
  }
  .portfolio-two__bg-box {
    display: none;
  }
  .event-two__points li + li {
    margin-left: 0px;
  }
  .thm-btn {
    padding: 16px 25px 16px;
  }
  .overlay-t {
    height: 256px;
    position: absolute;
    top: 48px;
    left: -18px;
    width: 378px;
  }
  .carousel-indicators {
    display: none !important;
  }
  .event-two__points {
    display: block;
  }
  .overlay-r {
    height: 256px;
    position: absolute;
    top: 329px;
    left: -12px;
    width: 376px;
  }
  .site-footer__top {
    display: inline-block;
    padding: 21px 25px 21px;
  }
  .site-footer__middle {
    display: block;
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .footer-widget__email-input-box {
    display: block;
    width: 55%;
  }
  .news-two__single {
    display: block;
    margin-bottom: 30px;
  }
  .event-two__left {
    padding-top: 65px;
    padding-bottom: 35px;
    padding-right: 15px;
  }
  .news-two__content {
    padding: 15px 10px 12px;
  }
  .about-two__shape-1 {
    display: none;
  }
  .about-two__img-2 {
    display: none;
  }
  .about-two__shape-2 {
    display: none;
  }
  .about-two__img {
    width: 242%;
    margin-top: 32px;
  }
  .about-two__right {
    margin-top: 45px;
  }
  .about-two__points {
    display: block;
  }
  .about-two__points-title-box .icon {
    width: 25px;
    height: 25px;
  }
  .about-two {
    margin-top: 840px;
  }
  .portfolio-two__right {
    padding: 22px !important;
    padding-left: 25px !important;
    margin-left: 0px;
  }
  .portfolio-two__right {
    margin-left: 0px;
    padding: 0;
    margin-top: 0;
    background-color: #0ca554;
    margin-bottom: 25px;
  }
  .portfolio-two__points-list li .content h3 {
    font-size: 22px;
  }
  .myfrm {
    display: none !important;
  }
  .brand-one__title {
    display: none;
  }
}
