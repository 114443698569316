.singlePost {
  width: 65%;
  margin: 30px;
  margin: auto;
}

.singlePostWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  padding-right: 0;
  padding: 10px;
  background-color: #fbfbfb;
  border: 1px solid rgb(251, 251, 251);
}

.singlePostImg {
  width: 90%;

  height: 400px;
  border-radius: 5px;
  object-fit: cover;
  margin: auto;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  margin: 10px 0px;
}
.single_post_content_image {
  width: 90%;
  object-fit: cover;
}
.single_post_content_heading {
  color: black;
  font-size: 22px;
  font-weight: 500;
  text-align: start;
  width: 100%;
  padding: 0px 10px;
}

.singlePostTitle {
  text-align: start;
  margin: 20px 10px;
  font-size: 25px;
  font-weight: 600;
}

.singlePostEdit {
  float: right;
  font-size: 16px;
}

.singlePostIcon {
  margin-left: 10px;
  cursor: pointer;
}

.singlePostIcon:first-child {
  color: #0b3561;
}
.singlePostIcon:last-child {
  color: tomato;
}

.singlePostInfo {
    width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: black;
  margin: 10px 0px;
}

.singlePostAuthor {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 17px;
  font-weight: 300;
}
.single_blog_post_time {
  font-size: 20px !important;
  color: #01448F;
}
.views_share_message_box {
  display: flex;
  align-items: center;
  gap: 10px;
}
.views_share_message {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
}

.singlePostDesc {
  color: #414040;
  font-size: 17px;
  font-weight: 300;
  padding: 0px 10px;
}

.blog_auther_information {
  width: 97%;
  border-top: 2px dashed #0072b1;
  margin: auto;
}

.blog_heading_auther {
  font-size: 30px;
  font-weight: 400;
}
.auher_info_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog_auther_icon {
  font-size: 60px !important;
  color: #0b3561;
  margin-top: 10px;
}
.auther_icon_name {
  display: flex;
  align-items: center;
  gap: 10px;
}
.auther_icon_name p {
  font-size: 18px;
  font-weight: 300;
}
.social_icons_links {
  display: flex;
  align-items: center;
  gap: 5px;
}
.single_blog_fb {
  color: white;
  background-color: #3e68c0;
  padding: 8px;
  border-radius: 50%;
}
.single_blog_twitter {
  color: white;
  background-color: #0072b1;
  padding: 8px;
  border-radius: 50%;
}
.single_blog_instagram {
  color: white;
  background: linear-gradient(-120deg, #4285f4, #ea4335, #fbbc05, #34a853);
  padding: 8px;
  border-radius: 50%;
}
.single_blog_google {
  color: white;
  background-color: #dd4b39;
  padding: 8px;
  border-radius: 50%;
}
/* ***************** responsive css ********************* */

@media only screen and (max-width: 1600px) {
  .single {
    width: 100% !important;
    margin: auto !important;
  }
  .singlePost {
    width: 60% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .singlePost {
    width: 65% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .singlePost {
    width: 90% !important;
  }
}
