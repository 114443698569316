
.gallary_page_component_container {
  width: 100vw;
  padding: 20px 0px;
}
.gallary_page_component_wrapper {
  width: 83%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
  padding: 40px 30px;
  margin: 40px auto 80px auto;
  background-color: #fbfbfb;
}
.features_heading_main {
  font-size: 32px;
  text-align: center;
}

.gallary_page_component_image_wrapper {
  width: 90%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* align-self:center; */
  flex-wrap: wrap;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
  padding: 20px 10px;
  background-color: white;
  margin: 40px auto;
}
.inActive_image_wrapper {
  display: none;
}
.gallary_page_component_image_container {
  width: 30%;
  position: relative;
  margin: 10px;
}
.gallary_page_component_image_container {
  background-color: #001c3b;
}
.textOverlay {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.gallary_page_component_image {
  /* opacity:1; */
  width: 30%;
  margin: auto;
  /* position: relative; */
}
.gallary_page_component_image:hover {
  opacity: 0.3;

}
  .middle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
  }
  

.gallary_page_component_image_container:hover .textOverlay{

  /* transition: 1s all ease-in; */
}

.gallary_page_component_image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin: 10px 10px;
  transition: 2s all ease-out;
  -webkit-transition: all 2s;
  border-radius: 5px;
  margin: auto;
 
}

.gallary_page_component_image:hover {
  opacity: 0.3;
  fill-opacity: calc(#e77918);
}

.overlaytext{
  display: none;
}
.gallary_page_component_image_container:hover  .overlaytext{
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: black;
  display:inherit;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  font-weight: 800;
  display: inherit;
 

  


}


/* **************** topbar css *************** */
.gallary_topBar {
  position: sticky;
  top: 0;
  z-index: 99;
  background: white;
  padding: 15px 0px;
  /* border-bottom: 3px solid #e77918; */
  /* border-top: 2px solid #e77918; */
  /* margin-top: 20px; */
  border: 2px solid rgb(233, 233, 233);
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 30px auto;
  padding: 8px 57px;
  border-radius: 5px;
}
.gallary_topBar ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  /* margin-top: 30px; */
  padding: 15px 0px;
}
.gallary_topBar_firstItem {
  display: flex;
  align-items: center;
  gap: 13px;
  color: #01448f !important;
  border: none;
  border-radius: 7px;
  font-size: 18px;
  font-weight: 800;
}
.gallary_topBar_non_active {
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgb(35, 35, 35);
  font-size: 15px;
  font-weight: 500;
}
/* .gallary_topBar li {
} */
.gallary_topBar ul a {
  text-decoration: none;
}
.gallery_topBarfirst_icon {
  color: black;
  font-size: 15px !important;
}
.gallary_topBar_icon {
  color: white;
  font-size: 25px !important;
  color: black;
  stroke: grey;
  stroke-width: 1px;
}

.gallary_topBar_icon_inactive {
  color: black;
}

/* *************** responsive css ********** */
@media only screen and (max-width: 1350px) {
  .gallary_page_component_wrapper {
    width: 88%;
    padding: 30px 20px;
  }
  .gallary_page_component_image_wrapper {
    width: 97%;
  }
  .gallary_page_component_image_container {
    width: 46%;
    position: relative;
    margin: 10px;
}
}
@media only screen and (max-width: 700px) {
  .gallary_page_component_image_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .gallary_page_component_image {
    width: 43%;
  }
  .features_heading_main {
    font-size: 32px;
  }
  .gallary_topBar ul {
    display: flex;
    gap: 10px;
  }
  .gallary_topBar_non_active {
    display: flex;
    gap: 5px;
    font-size: 12px;
    font-weight: 300;
  }
  .gallary_topBar_firstItem {
    display: flex;
    gap: 5px;
    font-size: 15px;
    font-weight: 500;
  }
  .gallary_page_component_image_container {
    width: 80%;
    position: relative;
    margin: 10px;
}

.gallary_page_component_image {
  width: 100%;
}

}

@media only screen and (max-width: 570px) {
  .gallary_page_component_image {
    width: 95%;
  }
  .gallary_topBar_firstItem {
    display: flex;
    gap: 5px;
    padding: 3px 5px;
  }
  .gallary_topBar_firstItem {
    display: flex;
    gap: 5px;
    padding: 3px 5px;
  }
  .gallary_page_component_image {
    width: 100%;
}
}

@media only screen and (max-width: 530px) {
  .gallary_topBar_icon {
    display: none !important ;
  }
  .gallary_page_component_image_container {
    width: 95%;
}
}
