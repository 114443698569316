.bg_image_3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../../../public/images//frontend//front5.jpeg");

  background-size: cover;
  background-position: center;
  color: #fff;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content h2 {
  font-size: 40px;
  font-weight: 800;
}
@media only screen and (max-width: 1000px) {
  .bg_image_3 {
    height: 185px;
  }
  .main_form {
    margin-top: 70px;
    margin-bottom: 40px;
}
}

@media only screen and (max-width: 500px) {
  .content h2 {
    font-size: 25px;
    font-weight: 600;
  }

}
