.services-two__single {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--govity-white);
    padding: 26px 30px 26px;
    padding-right: 20px;
    margin-bottom: 30px;
    justify-content: space-between;
    overflow: hidden;
    transition: all 0.5s linear;
    z-index: 1;
}

@media screen and (min-width:480px) and (max-width:776px) {

    .services-two__single{
        
    }
    .custom-class-responsive{
        
        width:50% !important;
    }
}