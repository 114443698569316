.latest-blog-area {
    position: relative;
    display: block;
    background: #ffffff;
    padding-top: 110px;
    padding-bottom: 50px;
}
.single-blog-post {
    position: relative;
    display: block;
    margin-bottom: 52px;
}
.single-blog-post .img-holder {
    position: relative;
    display: block;
    overflow: hidden;
}
.single-blog-post:hover .overlay-style-two{
	opacity: 1;
}
.single-blog-post:hover .img-holder .overlay-style-two:before{
	opacity: 1;
    transform: perspective(400px) rotateX(0deg);
    transition: all 700ms ease 100ms;	
}
.single-blog-post:hover .img-holder .overlay-style-two:after{
	opacity: 1;
    transform: perspective(400px) rotateX(0deg);
    transition: all 700ms ease 300ms;	
}
.single-blog-post .img-holder img {
    width: 100%;
    transform: scale(1.01);
    transition: all 700ms ease;
}
.single-blog-post:hover .img-holder img {
    transform:scale(1.2) rotate(2deg);	
}

.single-blog-post .img-holder .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transform: translate3d(0px, -100%, 0px);
    transition: all 700ms ease 300ms;
}
.single-blog-post:hover .img-holder .overlay{
    transition: all 700ms ease 500ms;
    transform: translate3d(0px, 0px, 0px);      
}
.single-blog-post .img-holder .overlay .box{
    display: table;
    width: 100%;
    height: 100%;
}
.single-blog-post .img-holder .overlay .box .link-icon{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.single-blog-post .img-holder .overlay .box .link-icon a span:before{
    display: inline-block;
    color: #ffffff;
    font-size: 35px;
    line-height: 35px;
    transition: all 500ms ease;
}
.single-blog-post .img-holder .overlay .box .link-icon a:hover span:before{
    color: #e9212e;    
}

.single-blog-post .text-holder {
    position: relative;
    display: block;
    padding-top: 20px;
}
.single-blog-post .text-holder .post-date {
    position: relative;
    display: inline-block;
    height: 45px;
    border-bottom: 3px solid #dadada;
    transition: all 700ms ease 500ms;
}
.single-blog-post .text-holder .post-date h3 {
    position: relative;
    display: block;
    color: #e9212e;
    font-size: 36px;
    line-height: 38px;
    font-weight: 600;
    text-transform: uppercase;
}
.single-blog-post .text-holder .post-date h3 span{
    color: #27282c;
    font-size: 14px;
}
.single-blog-post .meta-box {
    position: relative;
    display: block;
    overflow: hidden;
    min-height: 30px;
    margin-top: 16px;
    margin-bottom: 10px;
}
.single-blog-post .meta-box .meta-info {
    position: relative;
    display: block;
    overflow: hidden;
}
.single-blog-post .meta-box .meta-info li {
    position: relative;
    display: inline-block;
    float: left;
    line-height: 20px;
    padding-right: 16px;
    margin-right: 14px;
    font-weight: 500;
}
.single-blog-post .meta-box .meta-info li:before{
    position: absolute;
    top: 3px;
    right: 0px;
    width: 1px;
    height: 12px;
    background: #d3d9e0;
    content: "";
}
.single-blog-post .meta-box .meta-info li:last-child{
    padding-right: 0;
    margin-right: 0;
}
.single-blog-post .meta-box .meta-info li:last-child:before{
    display: none;
}
.single-blog-post .meta-box .meta-info li a{
    color: #e9212e;
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
    transition: all 500ms ease;
}
.single-blog-post .meta-box .meta-info li a:hover{
    color: #828282;
}
.single-blog-post .text-holder .blog-title {
    position: relative;
    display: block;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
}
.single-blog-post .text-holder .blog-title a{
    color: #27282c;
    transition: all 500ms ease;
}
.single-blog-post .text-holder .blog-title a:hover{
    color: #e9212e;
}

.single-blog-post .text-holder .text{
    display: block;
    margin-top: 12px;
}
.single-blog-post .text-holder .text p{
    margin: 0 0 26px;
    color: black;   
}

.custom-publication-button{
    padding: 10px;
    background-color: #01448f;
    color: white;
    border-radius: 5px;

}