.privacy_policy_slider {
    border-top-left-radius: 213px;
    border-top: 10px solid #136c47;
  }
  
  .privacy_policy_slider_image {
    width: 100vw;
    height: auto;
    object-fit: cover;
    border-bottom: 2px solid rgb(211, 207, 207);
    margin-bottom: 30px;
    border-top: 10px solid #ffd53f;
    border-top-left-radius: 213px;
  }
  
  .privacy_policy_container {
    width: 100vw;
    padding: 30px 0px;
  }
  .privacy_policy_wrapper {
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
    margin:40px auto;
    padding: 40px;
  }
  .privacy_policy_heading {
    font-size: 35px;
    font-weight: 500;
    text-align: center;
  }
  
  .policy_heading_desc {
  }
  .policy_heading_title {
    font-size: 16px;
    font-weight: 600;
    color: black;
  }
  .policy_desc_details {
    font-size: 16px;
    /* font-weight:300; */
    color: rgb(150, 148, 148);
  }
  
  .privacy_List_Item {
    display: flex;
    align-items: center;
    list-style: circle;
    gap: 10px;
  }
  .privacy_List_Item p {
    font-size: 16px;
    font-weight: 300;
  }
  .privacy_answer_quaetion_icon {
    font-size: 10px !important;
    color: black;
  }
  .privacy_List_Item_container {
    line-height: 1px;
  }
  
  
  /* ****************** Responsive css ************* */
  
  @media only screen and (max-width:1000px) {
    .privacy_policy_wrapper {
      width: 88%;
   
  }
    
  }
  @media only screen and (max-width: 700px) {
    .privacy_policy_wrapper {
      margin: 30px 20px;
      padding: 20px;
    }
    .terms_condition_heading {
      font-size: 32px;
    }
  }