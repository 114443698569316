.contact_details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.contact_details_headings {
  /* line-height: 15px; */
}
.contact_details h3 {
  font-size: 16px;
  color: #212529;
  margin: 0;
}
.contact_details h1 {
  font-size: 38px;
  font-weight: 500;
  margin: 0;
}
.contact_details p {
  font-size: 16px;
  font-weight: 300;
}
.details_info_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
}
.details_icon_info {
  display: flex;
  align-items: center;
  gap: 20px;
}
.detail_info {
  line-height: 15px;
}
.icons_detail{
  background-color: #01448F;
  color: white !important;
  padding: 13px;
  font-size: 50px !important;
  border-radius: 10px;

}
.details_icons {
  background-color: #01448F;
  padding: 17px 20px;
  color: white;
  font-size: 35px !important;
  stroke: white;
  stroke-width: 1px;
  border: 5px double rgb(255, 255, 255);
}
.detail_info h1 {
  font-size: 18px;
  font-weight: 500;
  color: black;
}
.detail_info p {
  font-size: 15px;
  font-weight: 500;
  color: black;
  padding-top: 3px;
  line-height: 30px;
}

@media only screen and (max-width: 1300px) {
  .contact_form_detail {
    width: 90vw !important;
    margin: auto !important;
  }
}

@media only screen and (max-width: 1200px) {
  .contact_form_detail {
    display: flex;
    flex-wrap: wrap;
  }
  .contact_details {
    width: 85vw;
  }
  .contact_details_headings h1{
    

  }
}
