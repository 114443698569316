* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;  
 
}
:root {
  --govity-font: "DM Sans", sans-serif;
  --govity-gray: #888888;
  --govity-gray-rgb: 136, 136, 136;
  --govity-white: #ffffff;
  --govity-white-rgb: 255, 255, 255;
  --govity-base: #0ca554;
  --govity-base-rgb: 12, 165, 84;
  --govity-black: #151515;
  --govity-black-rgb: 21, 21, 21;
  --govity-primary: #f3f3f3;
  --govity-primary-rgb: 243, 243, 243;
  --govity-bdr-color: #dddddd;
  --govity-bdr-color-rgb: 221, 221, 221;
  overflow-x: hidden;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
/*---------------------------------
# Top Header
-----------------------------------*/
body {
  font-family: "Montserrat", sans-serif !important;
  overflow-x: hidden ;
}
.hdgs button {
  background-color: #0ca554;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  padding: 29px 50px 29px;
}
 
.top-menu {
  display: flex;
  justify-content: end;
}
.header-transparent.header-fixed {
  box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
  -webkit-box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  position: fixed;
  background-color: #fff;
  z-index: 999;
  width: 100%;
  top: 0;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}
.header-transparent {
  background: transparent;
  top: 0;
  left: 0;
  right: 0;
  display: inline-block;
  width: 100%;
  z-index: 4;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.h-icon {
  color: #e77918;
  margin-right: 5px;
  font-size: 15px;
}
.head {
  font-size: 14px;
  font-weight: 400;
  color: #ccc;
  margin-right: 15px;
  font-family: "Montserrat", sans-serif;
}
.logo img {
  width: 110px;
}
.top-menu li a {
  text-decoration: none;
  color: #ccc;
  margin-right: 10px;
  font-size: 14px;
}
.header-img {
  background-image: url("https://i.ibb.co/8xh93Sr/ee731829-73a7-42b9-90bd-af82655d7815.jpg");
  height: 800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.top-header{
  padding: 15px 0px;

}
.rgba_img {
  position: absolute;
  width: 100%;
  height: 800px;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.4);
}
.top-header {
  z-index: 1;
  position: relative;
}
.feature_cat a {
  padding: 12px 0;
  text-align: center;
  width: 100%;
}
.bc-r h5 {
  margin-bottom: 15px;
}
.top-header ul {
  margin-top: 10px !important;
}

.nav-item {
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  color: var(--govity-gray);
  padding: 8px;
  font-family: "Montserrat", sans-serif;
}
.srch-ic {
  position: relative;
  align-items: center;
  padding: 33.5px 0px;
  justify-content: center;
  margin-left: -6px;
  margin-right: 30px;
  font-size: 21px;
}
.srch-ic::before {
  content: "";
  position: absolute;
  top: 20px;
  bottom: 20px;
  background-color: #dddddd;
  left: -30px;
  width: 1px;
}
.main-menu-two__btn-box {
  position: relative;
  display: flex;
  width: 100%;
}
.main-menu-two__btn {
  padding: 29px 50px 29px;
}
.up-t {
  height: 810px;
}
.kk-r {
}
.kk-r {
  animation: shake 6.5s;
  animation-iteration-count: infinite;
}
.overlay-t {
  height: 256px;
  position: absolute;
  top: 48px;
  left: 96px;
  width: 450px;
}
.overlay-r {
  height: 256px;
  position: absolute;
  top: 329px;
  left: 96px;
  width: 450px;
}
.facilities_content {
  display: table;
  width: 100%;
  height: 100%;
}
.facilities_sub {
  display: table-cell;
  vertical-align: middle;
}

.about-two__right p {
  margin-bottom: 5px !important;
  font-size: 15px;
  color: #777;
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/*---------------------------------
# Feature
-----------------------------------*/
.feature {
  position: relative;
  z-index: 222;
}

.feature-three {
  position: relative;
  display: block;
  padding-bottom: 90px;
}
.img-s {
  position: absolute;
  width: 100%;
  text-align: end;
  top: 250px;
  opacity: 0.09;
}
.icon-text {
  margin-top: 5px;
}
.img-bounce {
  -webkit-animation: ImgBounce 5s ease-in-out 0s infinite alternate;
  animation: ImgBounce 5s ease-in-out 0s infinite alternate;
}
.img-s img {
  width: auto;
}
/*---------------------------------
# About
-----------------------------------*/
.about-two {
  position: relative;
  display: block;
  margin-top: 70px;
  margin-bottom: 80px;
}
.hg-s i {
  border-radius: 50%;
  color: #fff;
  background-color: #01448f !important;
}
.bc-r h5 {
  color: #01448f;
  font-weight: 600;

}
.gv-d {
  position: relative;
  bottom: 140px;
  z-index: 1 !important;
}
.hg-s h4 {
  opacity: 0.2;
}
.jr-s:hover h5 {
  color: black;
  transition: 0.9s;
}
.jr-s:hover i {
  color: #01448f;
  background-color: #fff !important;
  transition: 0.9s;
}
.caro-t h2 {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: 46px;
}
.footer-widget__logo img {
  width: 110px;
}
.float-bob-x {
  animation-name: float-bob-x;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.about-two__shape-3 {
  position: absolute;
  left: -578px;
  top: -670px;
  width: 1100px;
  height: 1100px;
  border-radius: 50%;
  background-color: #f8f8f8;
  z-index: -1;
}
.float-bob-x {
  animation-name: float-bob-x;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes float-bob-x {
  0% {
    transform: translateX(-30px);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(-30px);
  }
}
.img-bounce {
  -webkit-animation: ImgBounce 5s ease-in-out 0s infinite alternate;
  animation: ImgBounce 5s ease-in-out 0s infinite alternate;
}
@keyframes ImgBounce {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-30px);
  }
}
.float-bob-y {
  -webkit-animation-name: float-bob-y;

  -webkit-animation-duration: 2s;

  -webkit-animation-iteration-count: infinite;

  -webkit-animation-timing-function: linear;
}
@keyframes float-bob-y {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(-20px);
  }
}
.about-two__left {
  position: relative;
  display: block;
  margin-right: 210px;
}
.about-two__img-box {
  position: relative;
  display: block;
}
.about-two__img {
  position: relative;
  display: block;
  z-index: 1;
}
.about-two__img img {
  width: 100%;
}
.about-two__img-2 {
  position: absolute;
  right: -100px;
  top: 202px;
  z-index: 1;
  border: 10px solid #fff;
}
.about-two__img-2 img {
  width: auto;
  height: 353px;
}
.about-two__shape-1 {
  position: absolute;
  top: -55px;
  right: -85px;
  width: 170px;
  height: 170px;
  border: 5px solid #01448f;
  z-index: -1;
}
.about-two__shape-2 {
  position: absolute;
  left: -117px;
  bottom: -86px;
}
.about-two__right {
  position: relative;
  display: block;
}
.about-two__right .section-title {
  margin-bottom: 15px;
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: #01448f !important;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #01448f !important;
}
.about_button a {
  padding: 18px 50px 18px;
}
.icon-box-wrapper {
  display: flex;
}
.icon_new {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 15px 0px 0px;
  background-color: #eee;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.icon_new i {
  color: #e77918;
  font-size: 24px;
}
.icon-wrapper:hover.icon-wrapper .icon {
  transform: rotate(360deg);
}
.section-title {
  position: relative;
  display: block;
  margin-top: -13px;
  margin-bottom: 48px;
}
.section-title__icon {
  position: relative;
  display: block;
  top: -14px;
}
.section-title__icon span {
  position: relative;
  display: inline-block;
  font-size: 13px;
  color: var(--govity-base);
}
.section-title__icon span {
  font-size: 13px;
  color: var(--govity-base);
}
.section-title__tagline {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  color: var(--govity-gray);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.section-title__title {
  margin: 0;
  color: var(--govity-black);
  font-size: 45px;
  line-height: 54px;
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing);
}
.about-two__text-1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: #01448f;
  letter-spacing: var(--govity-letter-spacing-two);
}
.about-two__text-2 {
  margin-top: 33px;
  margin-bottom: 22px;
}
.about-two__progress {
  position: relative;
  display: block;
  width: 100%;
}
.about-two__progress-single {
  position: relative;
  display: block;
}
.about-two__progress-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: var(--govity-black);
  margin-bottom: 6px;
  letter-spacing: var(--govity-letter-spacing-two);
}
.about-two__progress .bar {
  position: relative;
  width: 100%;
  height: 13px;
  border-radius: 6px;
  background-color: var(--govity-primary);
  margin-bottom: 12px;
}
.about-two__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 13px;
  border-radius: 6px;
  background: #01448f;
  transition: all 1500ms ease;
}
.about-two__progress .bar-inner.counted .count-text {
  opacity: 1;
}
.about-two__progress .count-text {
  position: absolute;
  right: 0px;
  bottom: 21px;
  color: var(--govity-gray);
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  letter-spacing: var(--govity-letter-spacing-two);
  opacity: 0;
  transition: all 500ms ease;
}
.about-two__points {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 32px;
}
.about-two__points li {
  position: relative;
  display: block;
}
.about-two__points-title-box {
  position: relative;
  display: flex;
  align-items: center;
}
.about-two__points-title-box .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: #e77918;
  border-radius: 50%;
}
.font-custom{
  font-size: 14px;
  padding: 8px 5px;

}
.about-two__points-title-box .icon span {
  position: relative;
  display: inline-block;
  font-size: 10px;
  color: var(--govity-white);
}
.about-two__points-title-box .icon span {
  font-size: 10px;
  color: var(--govity-white);
}
.about-two__points-title-box .title {
  position: relative;
  display: block;
  margin-left: 8px;
}
.about-two__points-title-box .title h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}
.about-two__points-text {
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 22px;
}
/*---------------------------------
# Feature
-----------------------------------*/
.f-btn {
  margin-left: 6px;
}
.feature-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 40px;
  color: var(--govity-white);
}
.feature-box {
  background-color: #01448f;
  padding: 47px 20px 47px 40px;
  overflow: hidden;
}
.feature-box2 {
  background-color: #01448f;
  padding: 47px 20px 47px 40px;
  overflow: hidden;
}
.feature-btn {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  letter-spacing: var(--govity-letter-spacing-two);
  transition: all 500ms ease;
}
.feature-icon {
  position: absolute;
  top: 175px;
  left: 40%;
  width: 123px;
  height: 123px;
  background-color: var(--govity-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%) translateY(-50%);
  transition: all 500ms ease;
}
.feture-icon {
  display: inline-block;
  font-size: 64px;
  color: var(--govity-base);
  transition: all 500ms ease;
}
.feature-iconn {
  position: absolute;
  top: 175px;
  left: 75%;
  width: 123px;
  height: 123px;
  background-color: var(--govity-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%) translateY(-50%);
  transition: all 500ms ease;
}
.feture-iconn {
  display: inline-block;
  font-size: 64px;
  color: var(--govity-base);
  transition: all 500ms ease;
}
.f-img {
  width: 100%;
  margin-left: -24px;
  height: 219px;
  position: relative;
  object-fit: cover;
}
/*service*/
.service {
  position: relative;
  display: block;
  max-width: 1370px;
  width: 100%;
  z-index: 3;
  background-color: var(--govity-primary);
  margin: 0 auto;
}
.services-two__shape-1 {
  position: absolute;
  right: 20px;
  top: 100px;
}
.services-two__right {
  position: relative;
  display: block;
  margin-top: 82px;
}
.services-two__left {
  position: relative;
  display: block;
}
.services-two__single::before {
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  top: 110%;
  left: 50%;
  background-color: #01448f;
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}
.services-two__single {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--govity-white);
  padding: 26px 30px 26px;
  padding-right: 20px;
  margin-bottom: 30px;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.5s linear;
  z-index: 1;
}
.services-two__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: var(--govity-letter-spacing-two);
}
.services-two__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}
.services-two__icon {
  position: relative;
  display: inline-block;
  top: 4px;
  margin-left: 16px;
}
.services-two__icon span {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: var(--govity-base);
  transition: all 500ms ease;
}
.services-two__icon span {
  font-size: 24px;
  color: #e77918;
}
.services-two__single:hover::before {
  top: -40%;
}
.services-two__single:hover .services-two__title a {
  color: var(--govity-white);
}
.services-two__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}
.services-two__single:hover .services-two__icon span {
  color: var(--govity-white);
}
.services-two {
  position: relative;
  display: block;
  z-index: 3;
}
/*---------------------------------
# Counter
-----------------------------------*/
.counter-two {
  position: relative;
  display: block;
  background-color: var(--govity-black);
  padding: 220px 0 105px;
  margin-top: -100px;
  z-index: 1;
}
.counter-two__bg {
  /* background-image: url("../../newtemplate/img/counter-two-bg.jpg"); */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.counter-two__inner {
  position: relative;
  display: block;
  border-top: 8px solid rgba(var(--govity-white-rgb), 0.2);
  padding-top: 40px;
}
.counter-two__list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.counter-two__list li {
  position: relative;
  display: flex;
  align-items: center;
}
.counter-two__count {
  position: relative;
  display: block;
  margin-right: 26px;
}
.counter-two__count h3 {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px !important;
  font-family: var(--govity-font) !important;
  letter-spacing: var(--govity-letter-spacing);
  color: var(--govity-base);
}
.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  font-family: "Helvetica Neue", sans-serif;
  line-height: 1.1em;
}
.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
  display: block;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  text-align: left;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}
.odometer.odometer-auto-theme .odometer-value,
.odometer.odometer-theme-default .odometer-value {
  text-align: center;
}
.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.counter-two {
  position: relative;
  display: block;
  background-color: var(--govity-black);
  padding: 220px 0 105px;
  margin-top: -100px;
  z-index: 1;
}
.nn-g {
  height: 300px;
  /* background-image: url(""); */
}
.counter-two__count > span {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px !important;
  font-family: var(--govity-font) !important;
  letter-spacing: var(--govity-letter-spacing);
  color: var(--govity-base);
  top: 14px;
  position: relative;
  left: -5px;
}
.counter-two__text {
  font-size: 20px;
  line-height: 24px;
  color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing-two);
}
.counter-two .section-title__title {
  color: var(--govity-white);
}
.counter-two .section-title__tagline {
  color: var(--govity-white);
}
/*---------------------------------
# tabs nav
-----------------------------------*/

.nav-item .nav-link,
.nav-tabs .nav-link {
  -webkit-transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;
  transition: all 300ms ease 0s;
}

.card a {
  -webkit-transition: all 150ms ease 0s;
  -moz-transition: all 150ms ease 0s;
  -o-transition: all 150ms ease 0s;
  -ms-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s;
}

[data-toggle="collapse"][data-parent="#accordion"] i {
  -webkit-transition: transform 150ms ease 0s;
  -moz-transition: transform 150ms ease 0s;
  -o-transition: transform 150ms ease 0s;
  -ms-transition: all 150ms ease 0s;
  transition: transform 150ms ease 0s;
}

[data-toggle="collapse"][data-parent="#accordion"][aria-expanded="true"] i {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.now-ui-icons {
  display: inline-block;
  font: normal normal normal 14px/1 "Nucleo Outline";
  font-size: inherit;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@-webkit-keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes nc-icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.now-ui-icons.objects_umbrella-13:before {
  content: "\ea5f";
}

.now-ui-icons.shopping_cart-simple:before {
  content: "\ea1d";
}

.now-ui-icons.shopping_shop:before {
  content: "\ea50";
}

.now-ui-icons.ui-2_settings-90:before {
  content: "\ea4b";
}

.nav-tabs {
  border: 0;
  padding: 15px 0.7rem;
}

.nav-tabs:not(.nav-tabs-neutral) > .nav-item > .nav-link.active {
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
}

.card .nav-tabs {
  border-top-right-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
}

.nav-tabs > .nav-item > .nav-link {
  color: #888888;
  margin: 0;
  margin-right: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 30px;
  font-size: 14px;
  padding: 11px 23px;
  line-height: 1.5;
}

.nav-tabs > .nav-item > .nav-link:hover {
  background-color: transparent;
}

.nav-tabs > .nav-item > .nav-link.active {
  background-color: #e77918;
  border-radius: 30px;
  color: #ffffff;
  border: none;
}

.nav-tabs > .nav-item > .nav-link i.now-ui-icons {
  font-size: 14px;
  position: relative;
  top: 1px;
  margin-right: 3px;
}

.nav-tabs.nav-tabs-neutral > .nav-item > .nav-link {
  color: #ffffff;
}

.nav-tabs.nav-tabs-neutral > .nav-item > .nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}
.icon_cat i {
  margin-right: 5px;
}
.tab_width {
  width: 90% !important;
}
.card {
  border: 0;
  border-radius: 0.1875rem;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

.card .card-header {
  background-color: transparent;
  border-bottom: 0;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
}

.card[data-background-color="orange"] {
  background-color: #f96332;
}

.card[data-background-color="red"] {
  background-color: #ff3636;
}

.card[data-background-color="yellow"] {
  background-color: #ffb236;
}

.card[data-background-color="blue"] {
  background-color: #2ca8ff;
}

.card[data-background-color="green"] {
  background-color: #15b60d;
}

[data-background-color="orange"] {
  background-color: #e95e38;
}

[data-background-color="black"] {
  background-color: #2c2c2c;
}

[data-background-color]:not([data-background-color="gray"]) {
  color: #ffffff;
}

[data-background-color]:not([data-background-color="gray"]) p {
  color: #ffffff;
}

[data-background-color]:not([data-background-color="gray"])
  a:not(.btn):not(.dropdown-item) {
  color: #ffffff;
}

[data-background-color]:not([data-background-color="gray"])
  .nav-tabs
  > .nav-item
  > .nav-link
  i.now-ui-icons {
  color: #ffffff;
}

@font-face {
  font-family: "Nucleo Outline";
  src: url("https://github.com/creativetimofficial/now-ui-kit/blob/master/assets/fonts/nucleo-outline.eot");
  src: url("https://github.com/creativetimofficial/now-ui-kit/blob/master/assets/fonts/nucleo-outline.eot")
    format("embedded-opentype");
  src: url("https://raw.githack.com/creativetimofficial/now-ui-kit/master/assets/fonts/nucleo-outline.woff2");
  font-weight: normal;
  font-style: normal;
}

.now-ui-icons {
  display: inline-block;
  font: normal normal normal 14px/1 "Nucleo Outline";
  font-size: inherit;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 768px) {
  .nav-tabs {
    display: inline-block;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;
  }

  .nav-tabs .nav-item > .nav-link {
    margin-bottom: 5px;
  }
}

/*---------------------------------
# portfolio
-----------------------------------*/

.portfolio-two {
  position: relative;
  display: block;
  padding: 120px 0 0;
  z-index: 1;
}
.portfolio-two__bg-box {
  display: block;
}
.portfolio-two__bg-box {
  position: absolute;
  top: 0;
  right: 0;
  width: 855px;
  height: 797px;
  background-color: var(--govity-base);
  overflow: hidden;
  z-index: -1;
}
.portfolio-two__shape-1 {
  position: absolute;
  bottom: -10px;
  right: 0;
  mix-blend-mode: multiply;
  opacity: 0.1;
}
.portfolio-two__shape-1 img {
  width: auto;
}
.portfolio-two__img img {
}
.portfolio-two__top {
  position: relative;
  display: block;
}
.portfolio-two__left {
  position: relative;
}
.portfolio-two__left .section-title {
  margin-bottom: 29px;
}
.portfolio-two__text {
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-gray);
  width: 85%;
}

.portfolio-two__points-box {
  position: relative;
  display: block;
}
.portfolio-two__points-top {
  position: relative;
  display: flex;
  align-items: center;
}
.portfolio-two__points-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: var(--govity-black);
  border-radius: 50%;
  transition: all 500ms ease;
}
.portfolio-two__points-icon span {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: var(--govity-white);
  transition: all 500ms ease;
}
.portfolio-two__points-icon span {
  font-size: 24px;
  color: var(--govity-white);
}
.portfolio-two__points-text {
  margin-left: 20px;
  color: var(--govity-white);
}
.portfolio-two__points-text p {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: var(--govity-letter-spacing);
}
.portfolio-two__points-text p a {
  color: inherit;
  transition: all 500ms ease;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 2px no-repeat;
  transition: all 500ms ease;
}
.portfolio-two__points-list {
  position: relative;
  display: block;
  margin-top: 23px;
}
.portfolio-two__points-list li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.portfolio-two__points-list li .content {
  position: relative;
  display: block;
}
.portfolio-two__points-list li .content p {
  letter-spacing: var(--govity-letter-spacing-two);
  color: #ceffe5;
  line-height: 26px;
  margin-bottom: 1px;
}
.portfolio-two__points-list li .content h3 {
  font-size: 26px;
  font-weight: 500;
  line-height: 26px;
  color: var(--govity-white);
}
.portfolio-two__points-list li .content h3 a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 2px no-repeat;
  transition: all 500ms ease;
}
.portfolio-two__points-list li .icon {
  position: relative;
  display: inline-block;
  top: 13px;
}
.portfolio-two__points-list li .icon span {
  position: relative;
  display: inline-block;
  font-size: 23px;
  color: var(--govity-white);
}
.portfolio-two__points-list li .icon span {
  font-size: 23px;
  color: var(--govity-white);
}
.portfolio-two__points-list li + li {
  border-top: 1px solid rgba(var(--govity-white-rgb), 0.2);
  padding-top: 32px;
  margin-top: 30px;
}
.portfolio-two__bottom {
  position: relative;
  display: block;
  padding-top: 45px;
}
.portfolio-two__bottom .masonary-layout {
  width: calc(100% + 10px);
  margin-left: -5px;
  margin-right: -5px;
}
.portfolio-two__single {
  position: relative;
  display: block;
  width: 100% !important;
}
.portfolio-two__img-box {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}
.portfolio-two__img::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(var(--govity-black-rgb), 0.4);
  visibility: hidden;
  transform-origin: top center;
  transform: scaleY(0) translateZ(100px);
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
}

.portfolio-two__single:hover .portfolio-two__content {
  visibility: visible;
  transform: translateY(0%);
  transition-delay: 500ms;
  opacity: 1;
}
.portfolio-two__single:hover .portfolio-two__content {
  visibility: visible;
}
.portfolio-two__title a:hover {
  color: var(--govity-base);
}
a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}
.portfolio-two__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}
.portfolio-two__single--all {
  min-height: 290px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.portfolio-two__all-portfolio {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.portfolio-two__all-portfolio-text {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 24px;
}
.portfolio-two__all-portfolio-icon {
  margin-left: 20px;
}
.portfolio-two__all-portfolio-icon a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 124px;
  width: 124px;
  background-color: var(--govity-primary);
  border-radius: 50%;
  font-size: 45px;
  color: var(--govity-gray);
  transition: all 500ms ease;
}
.portfolio-two__all-portfolio-icon a {
  font-size: 45px;
  color: var(--govity-gray);
}
.portfolio-two__points-list li .content h3 a:hover {
  background-size: 100% 2px;
}
.portfolio-two__points-icon:hover {
  background-color: var(--govity-white);
}
.portfolio-two__points-icon:hover span {
  color: var(--govity-black);
}
.portfolio-two__content {
  position: absolute;
  bottom: 30px;
  left: 0;
  transform: translateY(66%);
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
}
.portfolio-two__sub-title {
  position: relative;
  display: inline;
  font-size: 12px;
  color: var(--govity-white);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 22px;
  background-color: var(--govity-base);
  padding: 7px 30px 7px;
}
.portfolio-two__content {
  visibility: hidden;
}
.portfolio-two__title {
  position: relative;
  display: block;
  background-color: var(--govity-white);
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  padding: 18px 30px 18px;
  padding-right: 60px;
}
.portfolio-two__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}
.portfolio-two__single:hover .portfolio-two__img::before {
  visibility: visible;
  transform: scaleY(1) translateZ(0px);
}
/*---------------------------------
# Brand Logo Slider
-----------------------------------*/

.owl-nav {
  display: flex;
  justify-content: center;
}
.logo-img {
  width: 100%;
  position: relative;
  display: inline-block;
  z-index: 10;
  transition: 500ms;
  opacity: 0.2;
}
.logo-img:hover {
  opacity: 1;
}

.brand-logo {
  padding: 40px 0;
  position: relative;
}
.fa-angle-left::before {
  content: "\f104";
}
.fa-angle-left::before {
  content: "\f104";
}
.owl-prev {
  float: left;
  width: 12%;
  text-align: center;
  position: absolute;
  top: 175px;
  left: -82px;
}
.owl-next span {
  color: #fff;
  position: absolute;
  top: 172px;
}
.owl-prev span {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background: #2c3e50;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
.fa-angle-right::before {
  content: "\f105";
}
.owl-next span {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background: #2c3e50;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
.fa-angle-right::before {
  content: "\f105";
}
.owl-next span {
  line-height: 40px;
  color: #fff;
}
.owl-next {
  text-align: center;
}
.owl-prev {
  float: left;
  width: 25%;
  text-align: center;
}
.owl-next {
  float: right;
  width: 20%;
  text-align: center;
}
.brand-one__img::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--govity-primary);
  opacity: 0;
  transition: 0.5s;
  z-index: -1;
}
.brand-one__title::after {
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 0;
  content: "";
  height: 1px;
  background-color: #dddddd;
  max-width: 445px;
  width: 100%;
  transform: translateY(-50%);
}
.brand-one__title::before {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  content: "";
  height: 1px;
  background-color: #dddddd;
  max-width: 445px;
  width: 100%;
  transform: translateY(-50%);
}
.brand-one__title {
  position: relative;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 60px;
}
.brand-one__title {
  color: var(--govity-gray);
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
/*---------------------------------
# Department
-----------------------------------*/
.department-two {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
  overflow: hidden;
  padding: 50px 0 40px;
  z-index: 1;
}
.department-two__bg-one {
  /* background-image: url("../../newtemplate/img/department-two-bg-1.png"); */
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  height: 414px;
  opacity: 0.15;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  mix-blend-mode: luminosity;
  z-index: -1;
}
.department-two__bg-two {
  /* background-image: url("../../newtemplate/img/department-two-bg-2.png"); */
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  height: 485px;
  opacity: 0.4;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  mix-blend-mode: luminosity;
  z-index: -1;
}
.department-two__single {
  position: relative;
  display: block;
  margin-bottom: 60px;
}
.department-two__img-box {
  position: relative;
  display: block;
}
.department-two__img::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: rgba(var(--govity-black-rgb), 0.4);
  transform: perspective(400px) rotateX(90deg) scaleY(0.5);
  transform-origin: center;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
  z-index: 1;
}
.department-two__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}
.department-two__img img {
  width: 100%;
  transform: scale(1);
  transition: all 500ms ease;
}
.department-two__content {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 50px;
  background-color: var(--govity-white);
  padding: 15px 30px 15px;
  background-position: bottom left;
  transform: scale(1, 1);
  transform-origin: bottom center;
  transition: transform 500ms ease;
  z-index: 2;
}
.department-two__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.department-two__title a {
  color: var(--govity-black);
}
.department-two__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.department-two__icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -23px;
  right: 20px;
  width: 46px;
  height: 46px;
  background-color: #e77918;
  border-radius: 50%;
}
.department-two__icon span {
  position: relative;
  display: inline-block;
  font-size: 18px;
  color: var(--govity-white);
}
.department-two__icon span {
  font-size: 18px;
  color: var(--govity-white);
}
.department-two__content-two {
  position: absolute;
  bottom: -42px;
  left: 10px;
  right: 10px;
  background-color: var(--govity-white);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  padding: 15px 30px 10px;
  background-position: bottom left;
  transform: scale(1, 0);
  transform-origin: bottom center;
  transition: transform 500ms ease;
  z-index: 2;
}
.department-two__title-two {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.department-two__title-two a {
  color: var(--govity-black);
  transition: all 500ms ease;
}
.department-two__text {
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 6px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--govity-bdr-color);
}
.department-two__btn-box {
  position: relative;
  display: block;
}
.department-two__btn {
  color: var(--govity-gray);
  position: relative;
  display: flex;
  align-items: center;
  letter-spacing: var(--govity-letter-spacing-two);
  font-size: 14px;
  font-weight: 500;
  transition: all 500ms ease;
}
.department-two__title-two a:hover {
  color: #01448f;
}
.department-two__title a:hover {
  color: #01448f;
}
.department-two__btn i {
  font-size: 16px;
  margin-left: 10px;
  position: relative;
  top: 1px;
  transition: all 500ms ease;
}
.department-two__btn {
  letter-spacing: var(--govity-letter-spacing-two);
  font-size: 14px;
  font-weight: 500;
}
.department-two__btn i {
  font-size: 16px;
}
.department-two__icon-two {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -23px;
  right: 20px;
  width: 46px;
  height: 46px;
  background-color: #e77918;
  border-radius: 50%;
}
.department-two__icon-two span {
  position: relative;
  display: inline-block;
  font-size: 18px;
  color: var(--govity-white);
}
.department-two__icon-two span {
  font-size: 18px;
  color: var(--govity-white);
}
.department-two__single:hover .department-two__content-two {
  transform: scale(1, 1);
  transition-delay: 500ms;
}
.department-two__single:hover .department-two__content {
  transform: scale(0, 0);
  transition-delay: 300ms;
}
.department-two__single:hover .department-two__img::before {
  transform: perspective(400px) rotateX(0deg) scaleY(1);
}
.department-two__bottom-text {
  position: relative;
  display: block;
  text-align: center;
  padding-top: 23px;
  border-top: 1px solid var(--govity-bdr-color);
}
.department-two__bottom-text p {
  color: var(--govity-gray);
  font-size: 18px;
  line-height: 28px;
  letter-spacing: var(--govity-letter-spacing-two);
}
.department-two__bottom-text {
  text-align: center;
}
.department-two__bottom-text p a {
  color: var(--govity-black);
  transition: all 500ms ease;
}
.department-two__bottom-text p a:hover {
  color: var(--govity-base);
}
/*---------------------------------
# Event
-----------------------------------*/
.event-two {
  position: relative;
  display: block;
}
.event-two__img {
  position: absolute;
  bottom: 0;
  left: 0;
  mix-blend-mode: luminosity;
  opacity: 0.1;
  z-index: 1;
}
.event-two__img img {
  width: 100%;
}
.event-two__left::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10000px;
  right: -30px;
  bottom: 0;
  background-color: var(--govity-black);
}
.event-two__left::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10000px;
  right: -30px;
  bottom: 0;
  background-color: var(--govity-black);
}
.event-two__left {
  position: relative;
  display: block;
  padding-top: 120px;
  padding-bottom: 120px;
  padding-right: 74px;
  background-color: var(--govity-black);
}
.section-title {
  position: relative;
  display: block;
  margin-top: 0px;
  margin-bottom: 30px;
}
.section-title__icon {
  position: relative;
  display: block;
  top: -14px;
}
.section-title__icon span {
  position: relative;
  display: inline-block;
  font-size: 13px;
  color: var(--govity-base);
}
.section-title__icon span {
  font-size: 13px;
  color: #01448f;
}
.event-two__left .section-title__title {
  color: var(--govity-white);
}
.section-title__title {
  margin: 0;
  color: var(--govity-black);
  font-size: 42px;
  line-height: 46px;
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing);
}
.event-two__text-1 {
  font-size: 22px;
  line-height: 34px;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-base);
  position: absolute;
}
.event-two__text-2 {
  color: #777777;
  margin-top: 30px;
  margin-bottom: 30px;
}
.event-two__points {
  position: relative;
  display: flex;
  align-items: center;
}
.event-two__points li {
  position: relative;
  display: flex;
  align-items: center;
}
.event-two__points li .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: var(--govity-base);
  border-radius: 50%;
}
.event-two__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 10px;
  color: var(--govity-black);
}
.event-two__points li .icon span {
  font-size: 10px;
  color: var(--govity-black);
}
.event-two__points li .text {
  position: relative;
  display: block;
  margin-left: 10px;
}
.event-two__points li .text p {
  font-weight: 500;
  color: var(--govity-white);
  margin-bottom: 0px;
}
.event-two__points li + li {
  margin-left: 37px;
}
.event-two__btn::before {
  background-color: var(--govity-white);
}
.event-two__btn::before {
  background-color: var(--govity-white);
}
.event-two__right {
  position: relative;
  display: block;
  padding-left: 120px;
  padding-top: 120px;
  padding-bottom: 120px;
}
.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}
.owl-carousel,
.owl-carousel .owl-item {
  --webkit-tap-highlight-color: transparent;
  position: relative;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  --webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-stage::after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage {
  position: relative;
  --ms-touch-action: pan-Y;
  touch-action: manipulation;
  --moz-backface-visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  --ms-touch-action: pan-y;
  touch-action: pan-y;
  --webkit-user-select: none;
  --moz-user-select: none;
  --ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  --webkit-backface-visibility: hidden;
  --webkit-touch-callout: none;
}
.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  --webkit-backface-visibility: hidden;
  --moz-backface-visibility: hidden;
  --ms-backface-visibility: hidden;
  --webkit-transform: translate3d(0, 0, 0);
  --moz-transform: translate3d(0, 0, 0);
  --ms-transform: translate3d(0, 0, 0);
}
.owl-carousel,
.owl-carousel .owl-item {
  --webkit-tap-highlight-color: transparent;
  position: relative;
}
.event-two__list {
  position: relative;
  display: block;
}
.event-two__list li {
  position: relative;
  display: block;
}
.event-two__img-box {
  position: relative;
  display: block;
}
.event-two__img-one {
  position: relative;
  display: block;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.event-two__tag {
  position: absolute;
  top: 11px !important;
  right: 10px;
  background-color: var(--govity-base);
  padding: 4px 12px 4px;
}
.event-two__tag p {
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: var(--govity-white);
  margin-bottom: 0px;
}
.tv-y {
  background-color: var(--govity-base) !important;
  border-color: var(--govity-base) !important;
  border-radius: 0% !important;
}
.carousel-indicators {
  justify-content: start !important;
  margin-left: 0px !important;
  top: 269px !important;
}
.carousel-indicators ::before {
  content: "";
  position: absolute;
  z-index: -1 !important;
  width: 4px;
  height: 197px;
  top: -47px;
  right: 414px;
  background-image: linear-gradient(
    90deg,
    rgb(255, 255, 255) 1%,
    rgb(12, 165, 84) 50%,
    rgb(255, 255, 255) 100%
  );
}
.carousel-indicators [data-bs-target] {
  width: 8px !important;
}
.uit-m {
  opacity: 1 !important;
}
.carousel-indicators button {
  border: solid 2px var(--govity-base) !important;
  padding: 7px !important;
  width: 8px !important;
  z-index: 1 !important;
}
@media screen and (min-width: 1400px) {
  .overlay-t {
    left: 190px !important;
  }
  .overlay-r {
    left: 190px !important;
  }
  .carousel-indicators ::before {
    right: 492px !important;
  }
}
.event-two__content {
  position: absolute;
  bottom: 34px;
  left: 50px;
}
.event-two__meta {
  position: relative;
  display: flex;
  align-items: center;
}
.event-two__meta > li {
  position: relative;
  display: flex;
  align-items: center;
}
.event-two__list li {
  position: relative;
  display: block;
}
.event-two__meta li .icon span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--govity-base);
}
.event-two__meta li .icon span {
  font-size: 14px;
  color: var(--govity-base);
}
.event-two__meta li .text {
  position: relative;
  margin-left: 5px;
}
.event-two__meta li .text p {
  font-size: 14px;
  color: var(--govity-white);
  margin-bottom: 0px;
}
.event-two__meta > li + li {
  margin-left: 8px;
}
.event-two__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-top: 7px;
}
.event-two__title a {
  color: var(--govity-white);
  transition: all 500ms ease;
}
.event-two__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.event-two__list > li + li {
  margin-top: 30px;
}
.event-two__list li {
  position: relative;
  display: block;
}
.event-two__img-one {
  position: relative;
  display: block;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.event-two__tag {
  position: absolute;
  top: 57px;
  right: 10px;
  background-color: var(--govity-base);
  padding: 8px 20px 8px;
}
.event-two__tag p {
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: var(--govity-white);
}
.event-two__content {
  position: absolute;
  bottom: 34px;
  left: 50px;
}
.event-two__meta {
  position: relative;
  display: flex;
  align-items: center;
}
.event-two__meta > li {
  position: relative;
  display: flex;
  align-items: center;
}
.event-two__meta li .icon {
  position: relative;
  display: inline-block;
}
.event-two__meta li .icon span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--govity-base);
}
.event-two__meta li .icon span {
  font-size: 14px;
  color: var(--govity-base);
}
.event-two__meta li .text {
  position: relative;
  margin-left: 5px;
}
.event-two__meta li .text {
  position: relative;
  margin-left: 5px;
}
.event-two__meta > li + li {
  margin-left: 8px;
}
.event-two__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-top: 7px;
}
.event-two__title a {
  color: var(--govity-white);
  transition: all 500ms ease;
}
.owl-carousel .owl-stage::after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}
.owl-theme .owl-nav {
  margin-top: 10px;
}
.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  --webkit-tap-highlight-color: transparent;
}
.event-two__carousel.owl-carousel .owl-dots::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 270px;
  top: -47px;
  right: 10px;
  background-image: linear-gradient(
    90deg,
    rgb(255, 255, 255) 1%,
    rgb(12, 165, 84) 50%,
    rgb(255, 255, 255) 100%
  );
}
.event-two__carousel.owl-carousel .owl-dots {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  right: -68px;
  bottom: 34%;
}
.event-two__carousel.owl-carousel .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid var(--govity-bdr-color);
  background-color: var(--govity-white);
  margin: 10px 0px;
  padding: 0px;
  transition: all 500ms ease;
}
.event-two__carousel.owl-carousel .owl-dot.active {
  border: 2px solid var(--govity-base);
}
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.event-two__carousel.owl-carousel .owl-dots .owl-dot span {
  display: none;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  --webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}
.event-two__btn::before {
  background-color: var(--govity-white);
}
.event-two__btn:hover {
  color: var(--govity-base);
}
.event-two__left .section-title {
  margin-bottom: 30px;
}
/*---------------------------------
# Testimonails
-----------------------------------*/
.testimonails-bg {
  /* background-image: url("../../newtemplate/img/testimonial-two-bg.jpg"); */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mix-blend-mode: luminosity;
  z-index: -1;
}
.testimoial {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
  padding: 120px 0 120px;
  z-index: 1;
}
.testimonial-two__single::before {
  content: "";
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  background-repeat: no-repeat;
  background-position: bottom left;
  transform: scale(1, 0);
  transform-origin: bottom center;
  transition: transform 500ms ease;
  z-index: -1;
}
.testimonial-two__single {
  position: relative;
  display: block;
  text-align: center;
  padding: 0 56px 45px;
  margin-left: -29px;
  margin-right: -29px;
  z-index: 1;
}
.testimonial-two__single {
  text-align: center;
}
.testimonial-two__img::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  border: 6px solid #01448f;
  border-radius: 50%;
}
.testimonial-two__img {
  position: relative;
  display: block;
  width: 118px;
  margin: 0 auto;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.testimonial-two__img img {
  width: 100%;
  border-radius: 50%;
  border: 6px solid var(--govity-white);
}
.testimonial-two__rating {
  position: relative;
  display: flex;
  background-color: var(--govity-base);
  align-items: center;
  justify-content: center;
  max-width: 150px;
  margin: 0 auto;
  padding: 7px 20px 7px;
  margin-top: -10px;
}
.testimonial-two__rating span {
  position: relative;
  display: inline-block;
  font-size: 10px;
  color: var(--govity-white);
}
.testimonial-two__rating span {
  font-size: 10px;
  color: var(--govity-white);
}
.testimonial-two__text {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 17px;
  margin-top: 22px;
  color: var(--govity-gray);
}
.testimonial-two__client-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}
.testimonial-two__client-sub-title {
  font-size: 14px;
  letter-spacing: var(--govity-letter-spacing-two);
  line-height: 20px;
  color: var(--govity-gray);
}
.testimonial-two__single:hover::before {
  transform: scale(1, 1);
}
/*---------------------------------
# News
-----------------------------------*/
.news-two__single:hover .news-two__img::before {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg) scale(1);
}
.news-two__single:hover .news-two__img img {
  transform: scale(1.1) rotate(2deg);
}
.news-two__single {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}
.news-two__img-box {
  position: relative;
  display: block;
}
.news-two__img::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: rgba(var(--govity-black-rgb), 0.3);
  opacity: 1;
  transition: 0.5s;
  transform: perspective(400px) rotateY(-90deg) scale(0.2);
  transform-origin: top;
  z-index: 1;
}
.news-two__img {
  position: relative;
  display: block;
  width: 200px;
  overflow: hidden;
  z-index: 1;
}
.news-two__img img {
  width: 100%;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.7s;
  transition-property: all;
  height: 275px;
}
.news-two__date {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #01448f;
  padding: 10px 15px 0px;
  z-index: 2;
}
.news-two__date p {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: var(--govity-white);
  text-transform: uppercase;
  text-align: center;
}
.news-two__content {
  position: relative;
  display: block;
  padding: 30px 40px 13px;
  max-width: 370px;
  width: 100%;
  background-color: rgb(255, 255, 255);
}
.news-two__user-and-meta {
  position: relative;
  display: flex;
  align-items: center;
}
.news-two__user {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid var(--govity-bdr-color);
}
.news-two__user-img::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  z-index: -1;
}
.news-two__user-img {
  position: relative;
  display: block;
  z-index: 1;
}
.news-two__user-img img {
  width: auto;
  border-radius: 50%;
}
.news-two__user-text {
  position: relative;
  display: block;
  margin-left: 10px;
}
.news-two__user-text p {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: var(--govity-letter-spacing-two);
  margin-bottom: 0px;
}
.news-two__meta {
  position: relative;
  display: flex;
  align-items: center;
}
.news-two__meta .icon {
  position: relative;
  display: inline-block;
  margin-right: 4px;
}
.news-two__meta .icon span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--govity-base);
}
.news-two__meta .icon span {
  font-size: 14px;
  color: #e77918;
}
.news-two__meta .text {
  position: relative;
  display: block;
}
.news-two__meta .text p {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: var(--govity-letter-spacing-two);
  margin-bottom: 0px;
}
.news-two__title {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 13px;
  margin-bottom: 13px;
}
.news-two__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}
.news-two__text {
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-gray);
}
.news-two__btn {
  position: relative;
  display: block;
  border-top: 1px solid var(--govity-bdr-color);
  padding-top: 12px;
  margin-top: 19px;
}
.news-two__btn a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: var(--govity-letter-spacing-two);
  line-height: 24px;
  color: var(--govity-gray);
  transition: all 500ms ease;
}
.news-two__btn a i {
  position: relative;
  top: 1px;
  left: 9px;
}
.news-two__btn a {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: var(--govity-letter-spacing-two);
  line-height: 24px;
  color: var(--govity-gray);
}
.news-two__title a:hover {
  color: #01448f;
}
.news-two__btn a:hover {
  color: var(--govity-base);
}
.news-two__btn {
  position: relative;
  display: block;
  border-top: 1px solid var(--govity-bdr-color);
  padding-top: 12px;
  margin-top: 19px;
}
.news-two__btn a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: var(--govity-letter-spacing-two);
  line-height: 24px;
  color: var(--govity-gray);
  transition: all 500ms ease;
}
.news-two__btn a i {
  position: relative;
  top: 1px;
  left: 9px;
}
/*---------------------------------
# Social One
-----------------------------------*/
.social-one {
  position: relative;
  display: block;
  background-color: var(--govity-base);
  padding: 98px 0 95px;
  z-index: 1;
}
.social-one__bg {
  /* background-image: url("../../newtemplate/img/social-one-bg.jpg"); */
}
.social-one__text-two a:hover {
  color: var(--govity-black);
}
.social-one__text a:hover {
  color: var(--govity-black);
}
.social-one__icon:hover {
  background-color: var(--govity-white);
}
.social-one__icon:hover span {
  color: var(--govity-black);
}
.social-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  mix-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.social-one__icon {
  position: absolute;
  top: -58px;
  left: 50%;
  width: 117px;
  height: 117px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
  border-radius: 50%;
  background-color: rgb(21, 21, 21);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  transition: all 500ms ease;
}
.social-one__icon span {
  position: relative;
  display: inline-block;
  font-size: 49px;
  color: var(--govity-white);
  transition: all 500ms ease;
}
.social-one__icon span {
  font-size: 49px;
  color: var(--govity-white);
}
.social-one__text {
  font-size: 26px;
  font-weight: 400;
  line-height: 40px;
  color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing-two);
}
.social-one__text span {
  color: #ceffe5;
}
.social-one__text {
  font-size: 26px;
  font-weight: 400;
  line-height: 40px;
  color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing-two);
}
.social-one__inner {
  text-align: center;
}
.social-one__text a {
  color: #ceffe5;
  transition: all 500ms ease;
}
.social-one__text-two {
  font-size: 18px;
  color: #ceffe5;
  letter-spacing: var(--govity-letter-spacing);
  margin-top: 37px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-one__text-two a {
  font-weight: 700;
  color: var(--govity-white);
  margin-right: 23px;
  transition: all 500ms ease;
}
/*---------------------------------
#Footer
-----------------------------------*/
.site-footer {
  position: relative;
  display: block;
  background-color: var(--govity-black);
  z-index: 1;
}
.thm-btn:hover::before {
  top: -40%;
}
.footer-widget__subscribe-btn::before {
  background-color: var(--govity-white);
}
.site-footer__social a:hover::after {
  opacity: 1;
  transform: scaleY(1);
}
.site-footer__social a:hover {
  color: var(--govity-base);
  background-color: var(--govity-white);
}
.site-footer__social a:hover {
  color: #e77918;
}
.site-footer__social a + a {
  margin-left: 10px;
}
.footer-widget__link-list li a:hover {
  color: var(--govity-white);
}
.icon {
  margin-right: 10px;
}
.icon:hover {
  color: var(--govity-base);
}
.site-footer__bottom-text a:hover {
  color: #fff;
}
.site-footer__img {
  position: absolute;
  bottom: 0;
  left: 0;
  mix-blend-mode: multiply;
  opacity: 0.1;
  z-index: -1;
}
.site-footer__img img {
  width: auto;
}
.site-footer__top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
  padding: 40px 60px 40px;
}
.footer-widget__logo {
  position: relative;
  display: block;
}
a,
a:hover,
a:focus,
a:visited {
  text-decoration: none !important;
}
.footer-widget__subscribe-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: -30px;
  bottom: 0;
  width: 1px;
  background-color: rgba(var(--govity-white-rgb), 0.1);
}
.footer-widget__subscribe-box {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 785px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer-widget__subscribe-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: -30px;
  bottom: 0;
  width: 1px;
  background-color: rgba(var(--govity-white-rgb), 0.1);
}
.footer-widget__subscribe-text p {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing);
}
.footer-widget__email-box {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 540px;
  width: 100%;
}
.footer-widget__email-input-box {
  position: relative;
  display: block;
  max-width: 353px;
  width: 100%;
}
::placeholder {
  color: inherit;
  opacity: 1;
}
.footer-widget__email-input-box input[type="email"] {
  height: 48px;
  width: 100%;
  background-color: var(--govity-white);
  outline: none;
  font-size: 14px;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  color: var(--govity-gray);
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.footer-widget__subscribe-btn::before {
  background-color: var(--govity-white);
}
.thm-btn::before {
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  top: 110%;
  left: 50%;
  background-color: #e77918;
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}
.caro-t p {
  border-top: solid 7px #01448f;
  width: 280px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px !important;
}
.btn-box a {
  border-radius: 0px !important;
  background-color: #01448f;
  border-color: #01448f;
}
.be-f {
  margin-top: 90px !important;
}
.footer-widget__subscribe-btn {
  outline: none;
  border: none;
  background-color: var(--govity-base);
  margin-left: 10px;
  padding: 12px 50px 12px;
}
.thm-btn {
  border: none;
  position: relative;
  vertical-align: middle;
  appearance: none;
  outline: none !important;
  background-color: #01448f;
  overflow: hidden;
  transition: all 0.5s linear;
  z-index: 1;
  margin-right: 5px;
  width: 225px;
  display: inline-block;
}
.join_contact a {
  font-size: 16px;
  font-weight: 700;
}

.thm-btn {
  color: #fff !important;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}
.footer-widget__subscribe-box .mc-form__response {
  flex: 0 0 100%;
}
.site-footer__middle {
  position: relative;
  display: block;
  padding-top: 73px;
  padding-bottom: 80px;
}
.footer-widget__title-box {
  position: relative;
  display: block;
  margin-bottom: 24px;
}
.footer-widget__title {
  font-size: 20px;
  color: var(--govity-white);
  line-height: 28px;
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing-two);
}
.footer-widget__Contact-text {
  letter-spacing: var(--govity-letter-spacing-two);
  color: #777777;
  margin-bottom: 0px;
}
.footer-widget__Contact-list {
  position: relative;
  display: block;
  padding-top: 22px;
}
.footer-widget__Contact-list li {
  position: relative;
  display: flex;
  align-items: center;
}
.f-icon {
  color: var(--govity-base);
  font-size: 16px;
  margin-right: 12px;
}
.hover {
  font-size: 14px;
  /* font-weight: 700; */
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-white);
  margin-bottom: 5px;
}
.site-footer__social {
  display: flex;
  align-items: center;
  margin-top: 22px;
}
.footer-widget__Contact-list li:first-child .icon {
  position: relative;
  top: 3px;
}
.footer-widget__Contact-list li .icon {
  font-size: 16px;
  margin-right: 12px;
}
.footer-widget__Contact-list li .icon span {
  color: var(--govity-base);
}
.footer-widget__Contact-list li .text p {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing-two);
}
.footer-widget__Contact-list li .text p a {
  color: var(--govity-white);
}
.site-footer__social a {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--govity-white);
  background-color: #000000;
  font-size: 15px;
  border-radius: 50%;
  overflow: hidden;
  transition: all 500ms ease;
  z-index: 1;
}
.site-footer__social a::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--govity-white);
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.4s;
  transition-property: all;
  opacity: 1;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: -1;
}
.site-footer__social a {
  text-align: center;
  color: var(--govity-white);
  font-size: 15px;
}
.footer-widget__link-list li a {
  position: relative;
  color: #777777;
  letter-spacing: var(--govity-letter-spacing-two);
  transition: all 500ms ease;
}
.footer-widget__link-list li + li {
  margin-top: 8px;
}
.site-footer__bottom {
  position: relative;
  display: block;
  padding: 32px 0 34px;
  border-top: 1px solid #2d2d2d;
  background-color: rgba(0, 40, 104, 0);
  box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.1);
}
.site-footer__bottom-inner {
  position: relative;
  display: block;
  text-align: center;
}
.site-footer__bottom-text {
  color: #777777;
}
.site-footer__bottom-inner {
  text-align: center;
}
.site-footer__bottom-text a {
  color: #777777;
  transition: all 500ms ease;
}
/*---------------------------------
# search popup
-----------------------------------*/
.scroll-to-top {
  width: 50px;
  height: 50px;
  background: #e77918;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99;
  text-align: center;
  transition: all 0.4s ease;
  display: none;
  border-radius: 50%;
}
.scroll-to-top i {
  color: var(--govity-white);
  font-size: 18px;
  line-height: 50px;
  transition: all 0.4s ease;
  position: relative;
  display: inline-block;
}
.scroll-to-top i {
  color: var(--govity-white);
  font-size: 18px;
  line-height: 50px;
}
.scroll-to-top:hover i {
  color: #fff;
}
.scroll-to-top:hover {
  background-color: var(--govity-black);
}
.navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.icon-box-title a {
  color: #333;
}
.icon-box-title a:hover {
  color: #777;
}
.main_form {
  position: relative;
  display: flex;
  margin-top: 130px;
  margin-bottom: 50px;
}
.main_form h4 {
  font-size: 16px;
  padding-left: 25px;
}
.border_main {
  border: 1px solid #e5e7eb;
  background-color: #f1f1f1;
}
.form-control {
  border-radius: 1rem;
}
.left_space_icon {
  position: absolute;
  top: ;
  left: 20px;
}
.main_form label {
  font-size: 14px;
}
.payment_text {
  font-size: 14px;
}
#CardNumber {
  /* background-image: url(../img/cards.webp), url(../img/cards.webp); */
  background-position: 2px -121px, 260px -61px;
  background-size: 120px 361px, 120px 361px;
  background-repeat: no-repeat;
  padding-left: 54px;
}
.field.small {
  width: auto;
}
.field {
  padding-top: 15px;
  padding-right: 30px;
  width: 50%;
}
.title {
  font-size: 14px;
  padding-bottom: 8px;
  color: #6c757d !important;
}
.ddl {
  border-color: #f0f4f7;
  background-color: #fff;
  width: 100px;
  margin-right: 10px;
}
.input {
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  height: 40px;
  padding-left: 15px;
  font-weight: 600;
  font-size: 14px;
  color: #5e6977;
  border: 1px solid #ced4da;
}
.pay_today span {
  font-size: 14px;
}
.confirm_text {
  font-size: 14px;
}
#reservation_btn input {
  font-size: 14px;
}
.booking-btn {
  border: none !important;
  position: relative;
  vertical-align: middle !important;
  appearance: none !important;
  outline: none !important;
  background-color: #01448f !important;
  overflow: hidden;
  transition: all 0.5s linear !important;
  z-index: 1;
  margin-right: 5px;
  width: 180px;
  display: inline-block !important;
  color: #fff !important;
}
.booking-btn:hover {
  background-color: #e77918 !important;
}

.custom-icon{
  border: 2px solid white;

}