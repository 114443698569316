.button_component {
  display: inline-block;
  border-radius: 4px;
  background-color: #01448F;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  padding: 13px 20px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}
.button span:after {
  content: "\00bb"; /* CSS Entities. To use HTML Entities, use →*/
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.button:hover span {
  padding-right: 25px;
}
.button:hover span:after {
  opacity: 1;
  right: 0;
}
