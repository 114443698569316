/*about css */

.icon_about {
  font-size: 45px !important;
  stroke: #01448f;
  stroke-width: 1px;
}
.about_about_icon {
  color: white;
  background-color: #181e4e;
  padding: 10px;
  font-size: 40px;
}

.breadcrumbs {
  background-image: url("https://cdn.pixabay.com/photo/2015/01/09/11/08/startup-594090_640.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding: 110px 0;
  overflow-x: hidden;
}
.breadcrumbs:before {
  content: "";
  background-color: #000;
  opacity: 0.9;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.breadcrumbs .breadcrumbs-content {
  position: relative;
}
.breadcrumbs .breadcrumbs-content .page-title {
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  position: relative;
  padding-bottom: 22px;
}

/* .breadcrumbs .breadcrumbs-content .page-title:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 2px;
    width: 80px;
    background: #01448F;
} */
.breadcrumbs .breadcrumbs-content p {
  color: #fff;
  font-size: 14px;
  margin-top: 20px;
}
.breadcrumbs .breadcrumbs-content .breadcrumb-nav {
  background: transparent;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  display: block;
  display: inline-block;
  float: right;
}
.breadcrumbs .breadcrumbs-content .breadcrumb-nav li,
.breadcrumbs .breadcrumbs-content .breadcrumb-nav li a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.breadcrumbs .breadcrumbs-content .breadcrumb-nav li {
  display: inline-block;
}
.breadcrumbs .breadcrumbs-content .breadcrumb-nav li a {
  padding-right: 15px;
  margin-right: 15px;
  position: relative;
}

.breadcrumbs .breadcrumbs-content .breadcrumb-nav li a:after {
  content: "";
  height: 80%;
  width: 2px;
  background-color: #fff;
  position: absolute;
  top: 2px;
  right: 0;
}

.breadcrumbs .breadcrumbs-content .breadcrumb-nav li a:hover {
  color: #01448f;
}

/*=============================
	03. Start Features CSS
===============================*/
.Features {
  background: #fff;
}

.Features .feature-content {
  padding-left: 50px;
  padding-bottom: 30px;
}

.Features .feature-content .title {
  margin-bottom: 30px;
}

.Features .feature-content .title span {
  text-transform: capitalize;
  font-size: 14px;
  display: inline-block;
  background: #01448f;
  color: #fff;
  padding: 4px 18px;
  margin-bottom: 5px;
}

.Features .feature-content .title h3 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
}

.Features .feature-item {
  margin-bottom: 15px;
  position: relative;
  padding-left: 80px;
  padding-right: 30px;
}

.Features .feature-item:last-child {
  margin-bottom: 0;
}

.Features .feature-item .feature-thumb_1 {
  height: 55px;
  width: 55px;
  background: url("https://i.ibb.co/5vSz3ng/icons8-managment-64.png")
    no-repeat center center;
  background-size: contain;
  font-size: 23px;
  line-height: 55px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 10px;
}
.feature-thumb_2{
  height: 55px;
  width: 55px;
  background: url("https://i.ibb.co/jG8MdHD/icons8-sport-64.png")
    no-repeat center center;
  background-size: contain;
  font-size: 23px;
  line-height: 55px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 10px;
}
.feature-thumb_3{
  height: 55px;
  width: 55px;
  /* background: url("") */
  background: url("https://i.ibb.co/R4TnKNh/icons8-support-64.png")
    no-repeat center center;
  background-size: contain;
  font-size: 23px;
  line-height: 55px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 10px;
}

.Features .feature-item .banner-content {
  /* ! padding-left: 34px; */
  display: inline-block;
}

.Features .feature-item .banner-content .title {
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 38px;
}

.Features .feature-item .banner-content p {
  color: #777;
  font-weight: 400;
  font-size: 14px;
  display: block;
  margin-top: 6px;
}

.feature-right {
  position: relative;
  background-image: url("https://jtechit.com.pk/sandbox/gymkhanaclub/img/s11.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

/* .feature-right .watch-inner {
  display: inline-block;
  text-align: center;
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -40px;
  top: 50%;
  margin-top: -80px;
} */

/* .feature-right .video-head {
  display: inline-block;
  border-radius: 100%;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-top: 35px;
} */

/* .feature-right .video-head .video {
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
  background: #fff;
  color: #333;
  border-radius: 100%;
  display: inline-block;
  font-size: 28px;
  padding-left: 5px;
} */

/* .feature-right .video-head .video:hover {
  background: #01448F;
  color: #fff;
} */
/* 
.feature-right .video-head .video {
  height: 80px;
  width: 80px;
  background: #fff;
  color: #01448F;
  text-align: center;
  line-height: 84px;
  border-radius: 50%;
  position: relative;
  z-index: 5;
}

.feature-right .video-head .video:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid #fff;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: play-btn-1 1.8s linear infinite;
  -moz-animation: play-btn-1 1.8s linear infinite;
  -o-animation: play-btn-1 1.8s linear infinite;
  animation: play-btn-1 1.8s linear infinite;
}

.feature-right .video-head .video:after {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid #fff;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: play-btn-2 1.5s linear infinite;
  -moz-animation: play-btn-2 1.5s linear infinite;
  -o-animation: play-btn-2 1.5s linear infinite;
  animation: play-btn-2 1.5s linear infinite;
} */

/*=============================
	End Features CSS
===============================*/

/*=============================
	04. Start Services CSS
===============================*/
.services {
  background: #f9faff;
  padding-bottom: 85px;
}

.section {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
}

.services .section-title {
  margin-bottom: 65px;
}

.services .single-service {
  text-align: left;
  position: relative;
  padding: 130px 40px 45px 40px;
  box-shadow: 0px 5px 35px #0000000d;
  border-radius: 0;
  background: #fff;
  z-index: 5;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin: 15px 0;
}

.services .single-service .serial span {
  font-size: 30px;
  position: absolute;
  left: 40px;
  top: 40px;
  height: 65px;
  width: 65px;
  line-height: 65px;
  text-align: center;
  background: #74b9ff;
  color: #01448f;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #3e54ff0f;
  border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
}

.services .single-service:hover {
  background: #01448f;
}

.services .single-service:hover .serial span {
  border-color: transparent;
  background: #fff;
  color: #01448f;
  border-radius: 50%;
}

.services .single-service h3 a {
  color: #333;
  font-weight: 600;
  font-size: 22px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.services .single-service:hover h3 a {
  color: #fff;
}

.services .single-service p {
  margin-top: 20px;
  line-height: 24px;
  font-size: 14px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.services .single-service:hover p {
  color: #fff;
}

.services .single-service {
  position: relative;
  z-index: 2;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 15px;
}

.services .single-service img {
  height: 100%;
  width: 100%;
  z-index: 2;
}

.services .single-service .content {
  z-index: 2;
  width: 100%;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-top: 30px;
}

.services .single-service .content h4 {
  display: block;
  margin-bottom: 25px;
  padding-bottom: 15px;
  color: #181e4e;
  position: relative;
}

.services .single-service .content h4:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 3px;
  width: 80px;
  background: #3e54ff;
  z-index: 1;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.services .single-service:hover .content h4:before {
  width: 110px;
}

.services .single-service .content h4:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background: #e6eefb;
  z-index: -1;
}

.services .single-service .content h4 a {
  color: #fff;
  font-weight: 600;
  font-size: 23px;
  color: #181e4e;
}

.services .single-service .content p {
  color: #666;
  transition: all 0.4s ease;
}

.services .single-service .content .button {
  margin-top: 30px;
}

.services .single-service .content .button .btn {
  border: 2px solid #3e54ff;
  color: #3e54ff;
  background: transparent;
}

.services .single-service .content .button .btn:hover {
  border-color: transparent;
  color: #fff;
  background: #3e54ff;
}

/*=============================
  End Service CSS
===============================*/

/*=============================
	09. Start Pricing Table CSS
===============================*/
.pricing-table {
  background: #fff;
  position: relative;
}

.pricing-table .single-table {
  background: #fff;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding: 60px 20px;
  box-shadow: 0 10px 30px rgba(111, 111, 111, 0.1);
  overflow: hidden;
}

.pricing-table .single-table .popular {
  color: #fff;
  background: #3e54ff;
  display: inline-block;
  padding: 5px 70px;
  position: absolute;
  right: -60px;
  top: 14px;
  font-size: 13px;
  transform: rotate(45deg);
}

/* Table Head */
.pricing-table .single-table .table-head {
}

.pricing-table .single-table .title {
  font-size: 23px;
  font-weight: 600;
  line-height: 26px;
}

.pricing-table .single-table .title span {
  display: block;
  margin-top: 0;
  font-size: 13px;
  font-weight: 400;
  color: #888;
  text-transform: capitalize;
}

.pricing-table .single-table .price {
  height: 120px;
  width: 120px;
  display: inline-block;
  border: 1px dashed #74b9ff;
  border-radius: 50%;
  margin: 20px 0;
}

.pricing-table .single-table .amount {
  position: relative;
  color: #333;
  font-size: 35px;
  display: inline-block;
  margin: 45px 0;
  font-weight: 600;
}

.pricing-table .single-table .curency {
  color: #555;
  font-size: 17px;
  font-weight: 400;
  position: absolute;
  left: -14px;
  top: -7px;
}

.pricing-table .single-table .duration {
  color: #888;
  font-size: 11px;
  font-weight: 400;
  position: absolute;
  right: -25px;
  bottom: -6px;
}

/* Table List */
.pricing-table .table-list li {
  color: #444;
  margin-bottom: 5px;
  font-size: 14px;
}

.pricing-table .table-list li:last-child {
  margin-bottom: 0;
}

/* Table Bottom */
.pricing-table .button {
  margin-top: 30px;
}

/*=============================
	End Pricing Table CSS
===============================*/

/*======================================
   12. Start Clients CSS
========================================*/

.client-logo-section {
  background: #f9faff;
  padding: 50px 0;
}

.client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo {
  padding: 10px;
  text-align: center;
  margin: auto;
}

.client-logo-section
  .client-logo-wrapper
  .client-logo-carousel
  .client-logo
  img {
  max-width: 220px;
  width: 100%;
  opacity: 0.3;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.client-logo-section
  .client-logo-wrapper
  .client-logo-carousel
  .client-logo
  img:hover {
  opacity: 1;
}

/*======================================
   End Clients CSS
========================================*/

/*======================================
	16. Start Newsletter CSS
========================================*/
.newsletter {
  /* background-image: url('../images/pattern.png'); */
  background-color: #3e54ff;
}

.newsletter .subscribe-text h6 {
  font-size: 26px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 700;
}

.newsletter .subscribe-text p {
  font-size: 14px;
  color: #fff;
}

.newsletter .subscribe-form {
  position: relative;
}

.newsletter .common-input {
  height: 60px;
  width: 350px;
  border: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 0;
  padding: 0px 30px;
  font-weight: 500;
  font-size: 14px;
  background: transparent;
  border: 2px solid #eee;
  color: #fff !important;
}

.newsletter .subscribe-form input {
  opacity: 1;
  color: #fff !important;
}

.newsletter .subscribe-form input::-webkit-input-placeholder {
  opacity: 1;
  color: #fff !important;
}

.newsletter .subscribe-form input::-moz-placeholder {
  opacity: 1;
  color: #fff !important;
}

.newsletter .button {
  display: inline-block;
}

.newsletter .button button {
  height: 60px;
  background: #fff;
  color: #333;
  position: relative;
  top: -1px;
  margin-left: 10px;
}

.newsletter .button button .dir-part {
  background-color: #3e54ff;
}

.newsletter .button button:hover {
  border-color: #fff !important;
  color: #fff;
}

/*======================================
	End Newsletter CSS
========================================*/

/*======================================
	17. Start Footer CSS
========================================*/
.footer {
  background-color: #fff;
  z-index: 2;
  position: relative;
}

.footer .footer-top {
  padding-top: 80px;
  padding-bottom: 50px;
}

.footer .logo {
  margin-bottom: 25px;
}

.footer .logo img {
  width: 170px;
}

.footer .f-about {
  padding-right: 30px;
}

.footer .f-about p {
  color: #777;
  font-size: 14px;
  display: block;
  line-height: 27px;
}

.footer .f-about .footer-social {
  margin-top: 35px;
}

.footer .f-about .footer-social ul {
}

.footer .f-about .footer-social ul li {
  display: inline-block;
  margin-right: 6px;
}

.footer .f-about .footer-social ul li:last-child {
  margin: 0;
}

.footer .f-about .footer-social ul li a {
  font-size: 14px;
  color: #333;
  height: 35px;
  width: 35px;
  text-align: center;
  display: block;
  border: 1px solid #eee;
  line-height: 35px;
  background: #3e54ff;
  border: none;
  color: #fff;
}

.footer .f-about .footer-social ul li a:hover {
  background: #333;
  color: #fff;
}

.footer .newsletter-form {
  padding: 41px;
  border: 1px solid #eee;
}

.footer .newsletter-form form {
}

.footer .newsletter-form input {
  width: 400px;
  border-bottom: 3px solid #eee;
  color: #333;
  height: 60px;
  display: inline-block;
  border: none;
  border: 2px solid #eee;
  border-radius: 0;
  font-weight: 500;
  font-size: 14px;
  display: block;
  width: 100%;
  padding: 0px 20px;
}

.footer .newsletter-form .button {
  display: inline-block;
  width: 100%;
}

.footer .newsletter-form .button .btn {
  height: 60px;
  width: 100%;
  display: block;
  margin-top: 14px;
}

.footer .footer-middle {
  padding-bottom: 50px;
  padding-top: 50px;
}

.footer .single-footer {
  margin-top: 30px;
}

.footer .single-footer h3 {
  color: #333;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  text-transform: capitalize;
  margin-bottom: 25px;
}

/* Footer Link */
.footer .f-link ul li {
  margin-bottom: 13px;
  position: relative;
}

.footer .f-link ul li:last-child {
  margin: 0;
}

.footer .f-link ul li:hover:before {
  background: #333;
}

.footer.index2 .f-link ul li:hover:before {
  background: #333;
}

.footer .f-link ul li:last-child {
  margin-bottom: 0px;
}

.footer .f-link ul li a {
  display: inline-block;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.footer .f-link ul li a:hover {
  color: #3e54ff;
  padding-left: 5px;
}

/* Footer Contact */
.footer .f-contact {
}

.footer .f-contact p {
  color: #555;
  margin-bottom: 20px;
  line-height: 30px;
}

.footer .f-contact ul {
}

.footer .f-contact ul li {
  display: inline-block;
  color: #555;
  font-size: 14px;
  font-weight: 400;
}

.footer .f-contact ul li a {
  display: inline-block;
  color: #555;
  font-size: 14px;
  font-weight: 500;
  font-weight: 400;
}

.footer .f-contact ul li a:hover {
  padding: 0;
}

.footer .f-contact ul li a i {
}

.footer .f-contact ul li i {
  display: inline-block;
  margin-right: 7px;
  font-size: 17px;
  color: #3e54ff;
}

.footer .custom-flex {
  margin-right: -15px;
}

.footer .custom-flex li {
  width: calc(33.33% - 15px);
  margin-bottom: 15px;
  margin-right: 15px;
}

.footer .gallery ul {
  margin-left: -10px;
  overflow: hidden;
}

.footer .gallery ul li {
  float: left;
  margin: 10px 0 0 10px;
  padding: 0;
}

.footer .gallery ul li a {
  position: relative;
}

.footer .gallery ul li a:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #3e54ff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.footer .gallery ul li a:hover:before {
  opacity: 0.8;
  visibility: visible;
}

.footer .gallery ul li a i {
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 18px;
  color: #fff;
  display: block;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -15px;
  margin-top: -15px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
}

.footer .gallery ul li a:hover i {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.footer .gallery ul li img {
  width: 100px;
  height: 100px;
}

.footer .footer-bottom {
}

.footer .footer-bottom .inner {
  padding: 30px 0;
  border-top: 2px solid #eee;
}

.footer .footer-bottom p {
  color: #555;
}

.footer .footer-bottom p a {
  font-weight: 400;
  display: inline-block;
  margin-left: 6px;
  color: #333;
}

.footer .footer-bottom p a:hover {
  color: #3e54ff;
}

.footer .footer-bottom .left {
}

.footer .footer-bottom .right {
  text-align: right;
}

/*======================================
	End Footer	CSS
========================================*/

.free-version-banner {
  position: relative;
  background: #3e54ff;
  /* background-image: url('../images/pattern.png'); */
}

.free-version-banner .section-title {
  margin: 0;
  padding: 0;
  text-align: left;
}

.free-version-banner .section-title span {
  margin-bottom: 15px;
  background-color: #fff;
  color: #3e54ff !important;
}

.free-version-banner .section-title h2 {
  padding-bottom: 20px;
  margin-bottom: 25px;
  font-weight: 700;
  line-height: 50px;
  font-size: 35px;
}

.free-version-banner .section-title h2::before {
  left: 0 !important;
  margin-left: 0 !important;
  line-height: 55px !important;
}

.free-version-banner .button {
  z-index: 5;
  float: right;
}

.free-version-banner .button .btn {
  background-color: #fff;
  color: #3e54ff;
}

.free-version-banner .button .btn:hover {
  color: #fff;
}

.free-version-banner .button .btn .dir-part {
  background-color: #333;
}
