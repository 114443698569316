.cinema_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    overflow-x: hidden;
}
.cinema_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.picture_images {
    width: 100vw;
    height: 800px;
    object-fit: cover;
}

.once_description{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 75%;
 

}

.once_description h1 {
   font-size: 30px;
   padding: 30px 0px 10px 0px;
   font-weight: 500;
 
}
 
.once_description p {
   font-size: 15px;
   font-weight: 500;
   color: rgb(127, 126, 126);
   line-height: 25px;
   padding: 0px 20px; 
   
 
}
.image_sub{
    width: 98%;
    object-fit: cover;
    margin: 30px 0px;
   align-self: center;

}
