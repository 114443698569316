.position_absolute{

    position: absolute;
    top:-5px;
    right:-5px;
    width: 100px;
    height: 100px;
    object-fit: cover;



}