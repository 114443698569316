.profil-area {
  padding: 40px 0;
}
.prfile-img {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  border: 2px solid #01448f;
  background: #a7a9ac;
  object-fit: contain;
  margin: auto;
}
.profil-area .profile-name-img-area {
  padding: 65px 30px 65px 30px;
}

.profil-area .profile-name-img-area {
  padding: 65px 30px 65px 30px;
}
.profil-area .profile-name-img-area .profile-name h1 {
  font-size: 40px;
  font-weight: 700;
  text-transform: capitalize;
  color: #000;
  margin-bottom: 10px;
  margin-top: 20px;
}

.profil-area .profile-name-img-area .profile-name h4 {
  margin: 0;
  font-size: 18px;
  color: #01448f;
  font-weight: 900;
  letter-spacing: 3px;
  margin-bottom: 5px;
}
.social_profile ul {
  padding: 0;
  display: inline-flex;
  margin-top: 15px;
  margin-bottom: 0;
  list-style: none;
  gap: 10px;
}

.social_profile a {
  color: #01448f;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out 0s;
}
.profil-area .profile .profile-info table tbody tr td i {
  width: 32px;
  height: 32px;
  line-height: 32px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background: #01448f;
  display: inline-block;
  text-align: center;
  color: #fff;
  margin-right: 20px;
}

.profil-area .profile .profile-info table tbody tr td span {
  font-size: 15px;
  color: #000;
  font-weight: 700;
  text-transform: capitalize;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
.profil-area .profile .profile-info table tbody tr td b {
  margin: 0 15px;
  font-weight: 700;
}

.profil-area .profile .profile-info table tbody tr td {
  padding-top: 10px;
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
.profil-area .objective-area {
  padding: 30px;
  border-top: 1px solid;
  margin-top: 20px;
}
.section-title h3 {
  margin-top: 0px;
  font-size: 24px;
  color: #01448f;
  font-weight: 800;
  letter-spacing: 3px;
  margin-bottom: 10px;
}
.objective-content p {
  line-height: 29px;
}

@media only screen and (max-width: 500px) {
  .container,
  .container-sm {
    max-width: 540px;
  }

  .profil-area .profile-name-img-area .profile-name h1 {
    font-size: 25px;
    font-weight: 600;
  }
  .prfile-img {
    width: 100%;
    height: 80%;
  }
  .profil-area .profile-name-img-area .profile-name h4 {
    font-size: 12px;
    font-weight: 600;
  }
  .social_profile a {
    font-size: 14px;
  }
}
